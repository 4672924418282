import React from "react";
import type { WeatherData } from "../../models/weatherTypes";
import { dateOptions } from "../../models/constants";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getIconFromWeatherCode } from "../../utils/getIconFromWeatherCode";

interface MobileWeatherProps {
  locale: string;
  currentWeather: WeatherData;
  previsions: Array<WeatherData>;
}

const MobileWeather = ({
  locale,
  currentWeather,
  previsions,
}: MobileWeatherProps): JSX.Element => {
  return (
    <div className="rf-w-full rf-py-4 rf-flex rf-flex-col rf-items-center rf-overflow-y-scroll rf-scrollbar-hidden">
      {/* Date */}
      <div className="rf-pb-4 rf-title-large-bold-size-auto">
        {capitalizeFirstLetter(
          new Date().toLocaleDateString(locale, dateOptions)
        )}
      </div>

      {/* Current day icon */}
      <div className="rf-h-36 rf-w-36">
        {getIconFromWeatherCode(currentWeather.weather, "rf-h-full rf-w-full")}
      </div>

      {/* Current day Temperature */}
      <div className="rf-title-extralarge-size-auto">{`${Math.round(currentWeather.temperature.average || 0)}${currentWeather.temperature.unit}`}</div>

      {/* Next 6 days */}
      <div className="rf-w-full rf-mt-4 rf-grid rf-grid-cols-3 rf-items-center">
        {previsions.map((prevision, key) => {
          return (
            <React.Fragment key={key}>
              {/* Border between lines on grid display */}
              {key !== 0 && (
                <div className="rf-col-span-3 rf-border-t rf-border-t-menuBorder" />
              )}

              {/* Weather fields : day - icon - min/max temperatures */}
              <div className="rf-capitalize rf-font-bold">
                {capitalizeFirstLetter(prevision.day
                  ? new Date(prevision.day).toLocaleString(locale, {
                      weekday: "long",
                    })
                  : "NC")}
              </div>
              <div className="rf-py-2 rf-flex rf-justify-center">
                {getIconFromWeatherCode(prevision.weather, "rf-h-12")}
              </div>
              <div className="rf-flex rf-justify-end">{`${prevision.temperature.min}${prevision.temperature.unit} / ${prevision.temperature.max}${prevision.temperature.unit}`}</div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MobileWeather;
