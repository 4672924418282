import React from 'react';
import ReactDOM from 'react-dom';
import Weather from './components/Weather';

const renderWeather = (
  props: any,
  element: HTMLElement
): void => {
  ReactDOM.render(<Weather {...props} />, element);
}

export default renderWeather;
