const capitalizeFirstLetter = (text: string, locale = 'fr-FR'): string => {
  if (text.length > 0) {
    const first = text.charAt(0)
    const rest = text.slice(1)
    return first.toLocaleUpperCase(locale) + rest
  }

  return ''
}

export { capitalizeFirstLetter }
