import React, { ReactEventHandler, useEffect, useRef } from 'react'
import { useView } from '../Contexts/ViewContext'

interface BackgroundVideoProps {
  source: string
  style?: React.CSSProperties
  loop?: boolean
  muted?: boolean
  onVideoEnded?: ReactEventHandler<HTMLVideoElement>
  onVideoCanPlay?: ReactEventHandler<HTMLVideoElement>
}

const BackgroundVideo = ({
  source,
  style,
  loop,
  muted,
  onVideoEnded,
  onVideoCanPlay
}: BackgroundVideoProps): JSX.Element => {
  const { currentHeight, currentWidth } = useView()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.load()
      videoRef.current.play()
    }
  }, [videoRef])

  return (
    <video
      ref={videoRef}
      id='retorik-background-video'
      className='rf-relative rf-col-span-full rf-row-span-full rf-object-cover rf-overflow-hidden rf-filter rf-blur-background'
      onEnded={onVideoEnded}
      onError={onVideoEnded}
      onCanPlay={onVideoCanPlay}
      autoPlay
      loop={loop}
      muted={muted}
      playsInline
      style={{
        ...style,
        height: currentHeight,
        width: currentWidth
      }}
    >
      <source src={source} />
    </video>
  )
}

export default BackgroundVideo
