import React from 'react'
import { PhoneIcon } from '../../Icons/DetailedPOIIcons'

interface ShowPhoneNumberPorps {
  phoneNumber: string
}

const ShowPhoneNumber = ({
  phoneNumber
}: ShowPhoneNumberPorps): JSX.Element => {
  return (
    <div className='rf-px-5 rf-py-3 rf-mb-8 rf-flex rf-flex-row rf-items-center rf-font-bold'>
      <PhoneIcon className='rf-h-6 rf-w-6 rf-mr-2' />
      {phoneNumber}
    </div>
  )
}

export default ShowPhoneNumber
