import React from 'react'
import { useTelemetry } from '../Contexts/TelemetryContext'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'

const ErrorCollector = ({ children }): JSX.Element => {
  const { sendErrorTrace } = useTelemetry()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={sendErrorTrace}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorCollector
