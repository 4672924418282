import React, { useState } from 'react'
import useCloseButtonTop from '../../../hooks/useCloseButtonTop'
import { setCurrentSubView } from '../../Contexts/utilsStore'
import { CurrentSubView } from '../../../models/enums'
import { Dashboard } from '../Common'
import DashboardButton from './DashboardButton'
import Remote from './Remote'

const RemoteContainer = ({ hideMenu = false }): JSX.Element => {
  const top = useCloseButtonTop()
  const [dashboardOpened, setDashboardOpened] = useState<boolean>(false)

  /**
   * On call :
   *  - close subview if one is opened and if the parameter is false / undefined
   *  - toggle dashboardOpened state
   * @param doNotCloseViews : boolean
   */
  const toggleDashboard = (doNotCloseViews?: boolean): void => {
    !doNotCloseViews && setCurrentSubView(CurrentSubView.none)
    setDashboardOpened(!dashboardOpened)
  }

  return (
    <React.Fragment>
      <div
        className='rf-absolute rf-hidden large:rf-flex rf-flex-col rf-items-center rf-justify-start rf-w-24 rf-right-0 rf-text-xsm rf-text-trueblack'
        style={{
          top: top
        }}
      >
        {/* Dashboard button */}
        {!hideMenu && (
          <DashboardButton
            dashboardVisible={false}
            onClick={(): void => toggleDashboard()}
          />
        )}

        {/* Remote */}
        <Remote />
      </div>

      {/* Dashboard */}
      {dashboardOpened && (
        <Dashboard toggleDashboard={(): void => toggleDashboard(true)} />
      )}
    </React.Fragment>
  )
}

export default RemoteContainer
