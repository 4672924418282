{
  "fr-FR": {
    "change": "changer la langue",
    "select": "sélectionnez la langue de votre choix",
    "validate": "valider"
  },
  "en-US": {
    "change": "change the language",
    "select": "select the language you want to use",
    "validate": "validate"
  },
  "es-ES": {
    "change": "cambiar el idioma",
    "select": "seleccione la lengua que desea utilizar",
    "validate": "valide"
  },
  "de-DE": {
    "change": "die Sprache ändern",
    "select": "Wählen Sie die gewünschte Sprache",
    "validate": "validieren"
  }
}
