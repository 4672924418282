import React, { useState } from 'react'
import { useRetorik } from '../../Contexts/RetorikContext'
import { hooks } from 'botframework-webchat'
import { DeviceType, Mode } from '../../../models/enums'
import SendTextBox from '../../Utils/SendTextBox'
import ModeButton from './ModeButton'
import { MicrophoneButton } from '../Common'
import VoiceInput from '../../AnswerPanel/VoiceInput'
import { useView } from '../../Contexts/ViewContext'

const { useMicrophoneButtonClick, useSendBoxSpeechInterimsVisible } = hooks

interface MobileProps {
  parametersOpened: boolean
  toggleParameters: () => void
}

const Mobile = ({
  parametersOpened,
  toggleParameters
}: MobileProps): JSX.Element => {
  const {
    mode,
    setMode,
    setCanFocusSendBox,
    configuration: { hideMenu }
  } = useRetorik()
  const { currentDeviceType } = useView()
  const [fromTextToVocal, setFromTextToVocal] = useState<boolean>(false)
  const microphoneClick = useMicrophoneButtonClick()
  const [interimsVisible] = useSendBoxSpeechInterimsVisible()

  const toggleMode = (newMode: number): void => {
    // If the parameters are opened, no action
    if (!parametersOpened) {
      setFromTextToVocal(true)
      if (newMode === Mode.text) {
        interimsVisible && microphoneClick()
        setCanFocusSendBox(true)
      }
      setMode(newMode)
    }
  }

  const handleDashboardClick = (): void => {
    toggleParameters()
  }

  return (
    <div
      id='retorik-framework-menu'
      className='rf-w-full rf-h-fit rf-flex rf-flex-col-reverse rf-border-t rf-border-t-menuBorder rf-pointer-events-auto'
      style={{
        background: 'linear-gradient(to right, #F8F8FC, #EDEEF3)'
      }}
    >
      {/* Main buttons : text / vocal / parameters */}
      <div
        className={`rf-w-full rf-grid rf-flex-1 rf-h-full ${
          hideMenu ? 'rf-grid-cols-2' : 'rf-grid-cols-3'
        } rf-min-h-12 rf-grid-rows-1 rf-justify-center rf-items-end`}
      >
        {/* Text */}
        <ModeButton
          label='text'
          selected={parametersOpened ? false : mode === Mode.text}
          onClick={toggleMode}
        />

        {/* Vocal / Microphone */}
        {mode === Mode.vocal ? (
          <div className='rf-py-2 rf-w-full rf-flex rf-justify-center'>
            <MicrophoneButton openMicrophone={fromTextToVocal} />
          </div>
        ) : (
          <ModeButton label='vocal' selected={false} onClick={toggleMode} />
        )}

        {/* Parameters */}
        {!hideMenu && (
          <ModeButton
            label='dashboard'
            selected={parametersOpened}
            onClick={handleDashboardClick}
          />
        )}
      </div>

      {/* Input textbox */}
      {!parametersOpened && mode === Mode.text && (
        <SendTextBox
          className='rf-mx-3 rf-mt-2'
          withButton={currentDeviceType !== DeviceType.mobile}
        />
      )}

      {/* Speech interims */}
      {!parametersOpened && mode === Mode.vocal && (
        <VoiceInput className='rf-w-full rf-px-6 rf-py-2' />
      )}
    </div>
  )
}

export default Mobile
