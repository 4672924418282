import { create } from 'zustand'
import { RetorikActivity } from '../../models/activityTypes'

interface ActivityStore {
  messageActivities: Array<RetorikActivity>
  botMessageActivities: Array<RetorikActivity>
  lastBotMessageActivity: RetorikActivity | undefined
  queue: Array<RetorikActivity>
}

let timerRef

/**
 * Deal with the queue of activities that was formed before.
 * Call again after splicing the first element of the queue every 50ms
 * @param queue Array<RetorikActivity>
 */
const addActivitiesFromQueue = (queue: Array<RetorikActivity>): void => {
  if (queue.length > 0) {
    const currentState = useActivityStore.getState()
    useActivityStore.setState({
      messageActivities: [...currentState.messageActivities, queue[0]],
      botMessageActivities: [...currentState.botMessageActivities, queue[0]],
      lastBotMessageActivity: queue[0]
    })

    queue.splice(0, 1)
    setTimeout(() => {
      addActivitiesFromQueue(queue)
    }, 200)
  } else {
    useActivityStore.setState({ queue: [] })
  }
}

const updateActivities = (): void => {
  const currentState = useActivityStore.getState()

  if (currentState.queue.length === 1) {
    useActivityStore.setState({
      messageActivities: [
        ...currentState.messageActivities,
        currentState.queue[0]
      ],
      botMessageActivities: [
        ...currentState.botMessageActivities,
        currentState.queue[0]
      ],
      lastBotMessageActivity: currentState.queue[0],
      queue: []
    })
  } else {
    const processedQueue = currentState.queue.sort((a, b) => {
      if (!(a.id && a.id.includes('|'))) {
        return -1
      } else if (!(b.id && b.id.includes('|'))) {
        return 1
      } else {
        const id1 = parseInt(a.id.split('|')[1])
        const id2 = parseInt(b.id.split('|')[1])
        return isNaN(id1) ? -1 : isNaN(id2) ? 1 : id1 - id2
      }
    })

    // Process the queue to extract add each activity to the array
    addActivitiesFromQueue(processedQueue)
  }
}

export const useActivityStore = create<ActivityStore>()(() => {
  return {
    messageActivities: [],
    botMessageActivities: [],
    lastBotMessageActivity: undefined,
    queue: []
  }
})

export const addBotMessageActivity = (activity: RetorikActivity): void => {
  useActivityStore.setState({
    queue: [...useActivityStore.getState().queue, activity]
  })
  // Wait 1s after each call to be sure to get all activities replying to the same one
  timerRef && clearTimeout(timerRef)
  timerRef = setTimeout(() => {
    updateActivities()
  }, 500)
}
