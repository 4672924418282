/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useSpeech } from '../../Contexts/SpeechContext'
import { useRetorik } from '../../Contexts/RetorikContext'
import { useView } from '../../Contexts/ViewContext'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/menu.json'
import { HistoryButton, LanguageButton, TutorialButton } from '.'
import { Toggle } from '../../Utils'
import DisplaySubViews from '../ViewsDisplay/DisplaySubViews'
import { Mode } from '../../../models/enums'

interface ParametersProps {
  handleBack?: () => void
}

const Parameters = ({ handleBack }: ParametersProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { mode, displaySubtitles, setDisplaySubtitles } = useRetorik()
  const { muted, setMuted } = useSpeech()
  const { isMobile } = useView()

  const handleSoundChange = (val: boolean) => {
    setMuted(!val)
  }

  return (
    <React.Fragment>
      {/* Sound */}
      <Toggle
        className='rf-py-3 large:rf-py-0'
        title={translation[locale]?.sound || translation['fr-FR'].sound}
        handleChange={handleSoundChange}
        defaultChecked={!muted}
      />

      {/* Separating line only in mobile / widget modes */}
      {isMobile && (
        <div className='rf-h-0 rf-w-full rf-border-t rf-border-t-menuBorder' />
      )}

      {/* Subtitle */}
      {mode !== Mode.text && (
        <Toggle
          className='rf-py-3 large:rf-py-0'
          title={translation[locale]?.subtitle || translation['fr-FR'].subtitle}
          handleChange={setDisplaySubtitles}
          defaultChecked={displaySubtitles}
        />
      )}

      {/* Languages / Tutorial / History */}
      {isMobile && (
        <React.Fragment>
          <LanguageButton />
          <TutorialButton handleBack={handleBack} />
          <HistoryButton handleBack={handleBack} />
          <DisplaySubViews handleClick={handleBack} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Parameters
