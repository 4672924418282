import React, { useState } from 'react'
import { useLocaleStore, setLocale } from '../Contexts/localeStore'
import { setCurrentSubView } from '../Contexts/utilsStore'
import { useSendEvent } from 'botframework-webchat-api/lib/hooks'
import translation from '../../translations/language.json'
import languageInMotherTongue from '../../translations/languageCodeToMotherTongue.json'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { SubMenuClosing as Template } from '../Templates'
import { Radio } from '../Utils'
import { CurrentSubView } from '../../models/enums'

const LanguageChoice = (): JSX.Element => {
  const { locale, supported } = useLocaleStore()
  const sendEvent = useSendEvent()
  const [currentLanguage, setCurrentLanguage] = useState<string>(locale)

  const handleClose = (): void => {
    setCurrentSubView(CurrentSubView.none)
  }

  const handleChange = (language: string): void => {
    setCurrentLanguage(language)
  }

  /**
   * Change current locale with the one selected if they are different.
   * Send a 'TriggerWelcome' event to get the welcome message in the new language.
   * @param language string
   */
  const handleSelect = (language: string): void => {
    if (language !== locale) {
      setLocale(language)
      setTimeout(() => {
        sendEvent('TriggerWelcome', undefined)
      }, 150)
    }

    handleClose()
  }

  return (
    <Template handleClose={handleClose}>
      {/* Title */}
      <div className='large:rf-mt-[20vh] large-tactile:rf-mt-0 rf-title-bold-size-auto rf-font-bold'>
        {capitalizeFirstLetter(
          translation[locale]?.change || translation['fr-FR'].change
        )}
      </div>

      {/* Subtitle */}
      <div className='rf-text-lightgray rf-pt-1 rf-pb-2'>
        {capitalizeFirstLetter(
          translation[locale]?.select || translation['fr-FR'].select
        )}
      </div>

      {/* Languages */}
      {supported.map((language, key) => {
        return (
          <div
            key={key}
            className={`rf-w-full rf-py-4 rf-border-t rf-border-t-menuBorder ${
              key === 0 && 'large:rf-border-t-0'
            }`}
          >
            <Radio
              title={languageInMotherTongue[language]}
              checked={language === currentLanguage}
              handleChange={(): void => handleChange(language)}
            />
          </div>
        )
      })}

      {/* Validation button */}
      <div className='rf-w-full rf-mt-8 rf-flex rf-justify-center'>
        <button
          className='rf-px-8 rf-py-3 rf-text-secondary rf-font-bold rf-border-2 rf-border-secondary rf-rounded-lg'
          onClick={(): void => handleSelect(currentLanguage)}
        >
          {capitalizeFirstLetter(
            translation[locale]?.validate || translation['fr-FR'].validate
          )}
        </button>
      </div>
    </Template>
  )
}

export default LanguageChoice
