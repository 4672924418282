import { SpeechMarkdown } from '@davi-ai/speechmarkdown-davi-js'
const speechMarkdownParser = new SpeechMarkdown()

type ReturnType = {
  text: string
  replacements: Array<SpeechMarkdownReplacement>
}

type SpeechMarkdownReplacement = {
  replacementString: string
  speechMarkdown: string
}

export const getTextWithoutSpeechMarkdown = (
  text: string,
  speechMarkdownTagsPosition: Array<[number, number]> = []
): string => {
  let res = ''
  // Myna parser used in speechmarkdown parsing can't deal with dashes which aren't in speechmarkdown tags properly.
  // Let's change them and put them back after treatment
  if (text.indexOf('-') !== -1) {
    const dashesOutsideSpeechmarkdown: Array<number> = []
    for (let i = 0; i < text.length; i++) {
      if (text[i] === '-') {
        let test = true
        speechMarkdownTagsPosition.forEach((position) => {
          if (i >= position[0] || i <= position[1]) {
            test = false
          }
        })

        test && dashesOutsideSpeechmarkdown.push(i)
      }
    }

    let textWithoutDashes = ''
    if (dashesOutsideSpeechmarkdown.length > 0) {
      for (let i = 0; i < dashesOutsideSpeechmarkdown.length; i++) {
        textWithoutDashes +=
          i === 0
            ? text.substring(0, dashesOutsideSpeechmarkdown[i])
            : text.substring(
                dashesOutsideSpeechmarkdown[i - 1] + 1,
                dashesOutsideSpeechmarkdown[i]
              )
        textWithoutDashes += '1xx1'
      }
      // Add the rest of the text (after the last dash)
      textWithoutDashes += text.substring(
        dashesOutsideSpeechmarkdown[dashesOutsideSpeechmarkdown.length - 1] + 1
      )
    }

    res = speechMarkdownParser.toText(textWithoutDashes)
    res = res.replaceAll('1xx1', '-')
  } else {
    res = speechMarkdownParser.toText(text)
  }

  // Remove phoneme tag
  res = res.replaceAll(/<\/?phoneme?[^>]+>|$/g, '')
  return res
}

export const replaceSpeechMarkdownTags = (text: string): ReturnType => {
  const replacements: Array<SpeechMarkdownReplacement> = []
  let textWithReplacements = text
  // Get the indexes of all the occurences of ')['
  let indexes: Array<number> = []
  let position = text.indexOf(')[')

  while (position !== -1) {
    indexes.push(position)
    position = text.indexOf(')[', position + 1)
  }

  // If at least one occurence has been found escape all characters related
  if (indexes.length > 0) {
    // Loop on indexes to get all indexes
    for (let i = 0; i < indexes.length; i++) {
      // Get the opening parenthesis related to the closing one from the expression
      const openingParenthesisIndex = getOpeningParenthesis(text, indexes[i])
      // Get the closing bracket related to the opening one from the expression
      const closingBracketIndex =
        text.substring(indexes[i]).indexOf(']') + indexes[i]

      const replacement: SpeechMarkdownReplacement = {
        // Use string for replacement as : 'replacement1x1'
        replacementString: `replacement${i}x${i}`,
        speechMarkdown: text.substring(
          openingParenthesisIndex,
          closingBracketIndex + 1
        )
      }
      replacements.push(replacement)

      textWithReplacements = textWithReplacements.replace(
        replacement.speechMarkdown,
        replacement.replacementString
      )
    }
  }

  // In markdown links can be found as [...](...) so we need to find data between brackets without parentheses related to the closing bracket. Example : 'Hello. [1s] How are you ?'
  // Reset indexes array and set basic text to textWithReplacements to prevent searching again for brackets that have already been removed
  indexes = []
  const tempText = textWithReplacements
  position = tempText.indexOf(']')

  while (position !== -1) {
    indexes.push(position)
    position = tempText.indexOf(']', position + 1)
  }

  if (indexes.length > 0) {
    for (let i = 0; i < indexes.length; i++) {
      // If the index is the last character of the string, or if the next character isn't an opening parenthesis, it's ok
      if (
        indexes[i] === tempText.length - 1 ||
        tempText.charAt(indexes[i] + 1) !== '('
      ) {
        // Find the position of the previous opening bracket
        const openingBracketIndex = tempText
          .substring(0, indexes[i])
          .lastIndexOf('[')
        const replacement: SpeechMarkdownReplacement = {
          // Use string for replacement as : 'pause1x1'
          replacementString: `pause${i}x${i}`,
          speechMarkdown: tempText.substring(
            openingBracketIndex,
            indexes[i] + 1
          )
        }

        replacements.push(replacement)
        textWithReplacements = textWithReplacements.replace(
          replacement.speechMarkdown,
          replacement.replacementString
        )
      }
    }
  }

  return {
    text: textWithReplacements,
    replacements: replacements
  }
}

const getOpeningParenthesis = (text: string, index: number): number => {
  let nbOpeningParenthesis = 0
  let nbClosingParenthesis = 0
  // Search from the end of the string for an equivalence between opening and ending parenthesis, to prevent error if the text to be
  // spoken contains parenthesis.
  for (let i = index; i > 0; i--) {
    if (text.charAt(i) === ')') {
      nbClosingParenthesis++
    } else if (text.charAt(i) === '(') {
      nbOpeningParenthesis++
      if (nbClosingParenthesis === nbOpeningParenthesis) {
        return i
      }
    }
  }

  return 0
}
