{
  "ar-EG": {
    "samu": ".S.A.M.U",
    "police": "شرطة",
    "firefighter": "رجال الاطفاء",
    "general": "عام"
  },
  "ar-SA": {
    "samu": ".S.A.M.U",
    "police": "شرطة",
    "firefighter": "رجال الاطفاء",
    "general": "عام"
  },
  "ca-ES": {
    "samu": "S.A.M.U.",
    "police": "policia",
    "firefighter": "bombers",
    "general": "general"
  },
  "cs-CZ": {
    "samu": "S.A.M.U.",
    "police": "POLICIE",
    "firefighter": "hasiči",
    "general": "všeobecný"
  },
  "da-DK": {
    "samu": "S.A.M.U.",
    "police": "politi",
    "firefighter": "brandmænd",
    "general": "generel"
  },
  "de-AT": {
    "samu": "samu",
    "police": "polizei",
    "firefighter": "feuerwehrleute",
    "general": "allgemein"
  },
  "de-CH": {
    "samu": "samu",
    "police": "polizei",
    "firefighter": "feuerwehrleute",
    "general": "allgemein"
  },
  "de-DE": {
    "samu": "samu",
    "police": "polizei",
    "firefighter": "feuerwehrleute",
    "general": "allgemein"
  },
  "en-CA": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "en-GB": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "en-HK": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "en-IE": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "en-IN": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "en-US": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "es-ES": {
    "samu": "samu",
    "police": "policía",
    "firefighter": "bomberos",
    "general": "general"
  },
  "es-MX": {
    "samu": "samu",
    "police": "policía",
    "firefighter": "bomberos",
    "general": "general"
  },
  "fi-FI": {
    "samu": "S.A.M.U.",
    "police": "Poliisi",
    "firefighter": "palomiehiä",
    "general": "yleistä"
  },
  "fr-BE": {
    "samu": "samu",
    "police": "police",
    "firefighter": "pompiers",
    "general": "général"
  },
  "fr-CA": {
    "samu": "samu",
    "police": "police",
    "firefighter": "pompiers",
    "general": "général"
  },
  "fr-CH": {
    "samu": "samu",
    "police": "police",
    "firefighter": "pompiers",
    "general": "général"
  },
  "fr-FR": {
    "samu": "samu",
    "police": "police",
    "firefighter": "pompiers",
    "general": "général"
  },
  "hi-IN": {
    "samu": "एस.ए.एम.यू.",
    "police": "पुलिस",
    "firefighter": "अग्निशमन कर्मी",
    "general": "जनरल"
  },
  "hu-HU": {
    "samu": "S.A.M.U.",
    "police": "rendőrség",
    "firefighter": "tűzoltók",
    "general": "tábornoki"
  },
  "id-ID": {
    "samu": "S.A.M.U.",
    "police": "POLISI",
    "firefighter": "petugas pemadam kebakaran",
    "general": "umum"
  },
  "it-IT": {
    "samu": "S.A.M.U.",
    "police": "Polizia Stradale",
    "firefighter": "vigili del fuoco",
    "general": "generale"
  },
  "ja-JP": {
    "samu": "S.A.M.U",
    "police": "警察",
    "firefighter": "消防士",
    "general": "一般的な"
  },
  "ko-KR": {
    "samu": "S.A.M.U.",
    "police": "경찰",
    "firefighter": "소방관",
    "general": "일반적인"
  },
  "nb-NO": {
    "samu": "S.A.M.U.",
    "police": "politiet",
    "firefighter": "brannmenn",
    "general": "generell"
  },
  "nl-BE": {
    "samu": "S.A.M.U.",
    "police": "politie",
    "firefighter": "brandweerlieden",
    "general": "algemeen"
  },
  "nl-NL": {
    "samu": "S.A.M.U.",
    "police": "politie",
    "firefighter": "brandweerlieden",
    "general": "algemeen"
  },
  "pl-PL": {
    "samu": "S.A.M.U.",
    "police": "Policja",
    "firefighter": "strażacy",
    "general": "ogólny"
  },
  "pt-BR": {
    "samu": "S.A.M.U.",
    "police": "polícia",
    "firefighter": "bombeiros",
    "general": "general"
  },
  "pt-PT": {
    "samu": "S.A.M.U.",
    "police": "polícia",
    "firefighter": "bombeiros",
    "general": "general"
  },
  "ru-RU": {
    "samu": "С.А.М.У.",
    "police": "полиция",
    "firefighter": "пожарные",
    "general": "общий"
  },
  "sv-SE": {
    "samu": "S.A.M.U.",
    "police": "polis",
    "firefighter": "brandmän",
    "general": "allmän"
  },
  "th-TH": {
    "samu": "S.A.M.U.",
    "police": "ตำรวจ",
    "firefighter": "นักดับเพลิง",
    "general": "ทั่วไป"
  },
  "tr-TR": {
    "samu": "S.A.M.U.",
    "police": "polis",
    "firefighter": "itfaiyeciler",
    "general": "genel"
  },
  "zh-CN": {
    "samu": "S.A.M.U.",
    "police": "警察",
    "firefighter": "消防员",
    "general": "将军"
  },
  "zh-HK": {
    "samu": "S.A.M.U.",
    "police": "警察",
    "firefighter": "消防员",
    "general": "将军"
  },
  "zh-TW": {
    "samu": "S.A.M.U.",
    "police": "警察",
    "firefighter": "消防员",
    "general": "将军"
  }
}

