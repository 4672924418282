/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import type { UrlData } from '../../models/linkAttachment'
import { useLocaleStore } from '../Contexts/localeStore'
import { useRetorik } from '../Contexts/RetorikContext'
import translation from '../../translations/linkattachment.json'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { checkFileNameInUrl } from '../../utils/checkFileNameInUrl'
import ClassicButton from './Utils/ClassicButton'
import BorderlessButton from './Utils/BorderlessButton'
import { InfoWithCircleIcon } from '../Icons/Miscellaneous'
import QRCode from 'qrcode'

interface LinkAttachmentProps {
  urlData: UrlData
  fileName?: string
  qrCode?: string
  description?: string
  height?: number
}

const LinkAttachment = ({
  urlData,
  fileName,
  qrCode,
  description,
  height
}: LinkAttachmentProps): JSX.Element => {
  const { configuration } = useRetorik()
  const { locale } = useLocaleStore()
  !fileName && (fileName = checkFileNameInUrl(urlData.url))
  const title = urlData.title || fileName
  const [qrCodeData, setQrCodeData] = useState<string | undefined>(qrCode)

  /**
   * On call :
   *  - get text to display depending on configuration, urlData and locale
   * @returns string
   */
  const getTextTodisplay = (): string => {
    let textToDisplay = ''
    const downloadText =
      translation[locale]?.download || translation['fr-FR'].download
    const gotoText = translation[locale]?.goto || translation['fr-FR'].goto
    const clickText = translation[locale]?.click || translation['fr-FR'].click
    const scanQRText =
      translation[locale]?.scanQR || translation['fr-FR'].scanQR

    if (configuration.isUsedOnBorne) {
      if (urlData.download) {
        if (urlData.title) {
          textToDisplay = `${downloadText} \u00ab ${fileName} \u00bb`
        } else {
          textToDisplay = downloadText
        }
      } else {
        textToDisplay = gotoText
      }

      textToDisplay += ` ${scanQRText}`
    } else {
      if (urlData.download) {
        if (urlData.title) {
          textToDisplay = `${downloadText} ${fileName}`
        } else {
          textToDisplay = downloadText
        }
      } else {
        textToDisplay = clickText
      }
    }

    return textToDisplay
  }

  /**
   * On component creation :
   *  - call setQrCodeDataAsync only if used on a borne
   */
  useEffect(() => {
    !qrCode && configuration.isUsedOnBorne && setQrCodeDataAsync()
  }, [])

  /**
   * On call :
   *  - generate a qrcode as dataURL
   *  - set qrCodeData state with the data
   */
  const setQrCodeDataAsync = async (): Promise<void> => {
    const qrCodeString = await QRCode.toDataURL(urlData.url)
    setQrCodeData(qrCodeString)
  }

  return (
    <div
      className={`rf-w-full rf-p-[25px] rf-flex rf-flex-col rf-items-center ${
        title || description ? 'rf-justify-between' : 'rf-justify-around'
      } rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-overflow-y-scroll rf-scrollbar-thin`}
      style={{
        height: height,
        touchAction: 'pan-y'
      }}
    >
      {title && (
        // Title on the left if there is a description, middle otherwise
        <div
          className={`rf-w-full rf-mb-4 ${
            description ? 'rf-text-left' : 'rf-text-center'
          } rf-title-size-auto ${
            configuration.isUsedOnBorne ? 'rf-truncate' : 'rf-line-clamp-2'
          }`}
        >
          {capitalizeFirstLetter(title)}
        </div>
      )}
      {configuration.isUsedOnBorne ? (
        <React.Fragment>
          <div className='rf-w-full rf-mb-4 rf-flex rf-flex-row rf-items-center rf-justify-center'>
            {description && <div className='rf-mb-4'>{description}</div>}
            {qrCodeData && (
              <img className='rf-h-full' src={qrCodeData} alt='QR Code' />
            )}
          </div>
          <div className='rf-relative rf-text-center rf-mx-8 rf-italic'>
            <InfoWithCircleIcon className='rf-absolute rf-top-[0.125rem] -rf-left-6 rf-h-4' />
            {getTextTodisplay()}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {description && <div className='rf-mb-4'>{description}</div>}
          {urlData.download &&
          urlData.title &&
          getTextTodisplay().length >= 40 ? (
            <BorderlessButton
              url={urlData.url}
              text={getTextTodisplay()}
              download={true}
            />
          ) : (
            <ClassicButton
              url={urlData.url}
              text={getTextTodisplay()}
              description={!!description}
              download={!!urlData.download}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default LinkAttachment
