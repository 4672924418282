/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef, useState } from 'react'
import useShowPOI from '../../hooks/useShowPOI'
import { useView } from '../Contexts/ViewContext'
import { useRetorik } from '../Contexts/RetorikContext'
import { setRetorikEvent } from '../Contexts/utilsStore'

import type { POIContentType } from '../../models/attachmentTypes'
import { getColorFromCategoryId } from '../../utils/getColorFromCategoryId'
import { getColorWithTransparency } from '../../utils/colorUtils'
import { Mode, RetorikEvent } from '../../models/enums'

import Template from '../Templates/FullScreenRemoteClosing'
import DetailedTitle from './DetailedAttachment/DetailedTitle'
import DetailedText from './DetailedAttachment/DetailedText'
import DetailedMedia from './DetailedAttachment/DetailedMedia'
import DetailedAddress from './DetailedAttachment/DetailedAddress'
import DownloadSheet from './DetailedAttachment/DownloadSheet'
import { useSpring, easings } from '@react-spring/web'

const springEnterDuration = 1500
const springLeaveDuration = 2500

const AttachmentDetails = (): JSX.Element => {
  const askForDetailedPOI = useShowPOI()
  const { mode } = useRetorik()
  const { themeColors, isMobile } = useView()
  const [content, setContent] = useState<POIContentType | undefined>(undefined)
  const [color, setColor] = useState<string>(themeColors.secondary)
  const [colorWithTransparency, setColorWithTransparency] = useState<string>(
    'rgba(25, 153, 177, 0.5)'
  )
  const [titleHeight, setTitleHeight] = useState<number>(0)
  const [spring, api] = useSpring(() => ({
    from: {
      transform: 'translateX(0%)'
    }
  }))
  const timerRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    setContent(askForDetailedPOI)
    if (askForDetailedPOI) {
      setRetorikEvent(RetorikEvent.DetailViewOpen)
    }
  }, [askForDetailedPOI])

  useEffect(() => {
    if (content && content.category?.id) {
      setColor(getColorFromCategoryId(content.category.id, themeColors))
      api.start({
        from: {
          transform: 'translateX(-100%)'
        },
        to: {
          transform: 'translateX(0%)'
        },
        config: {
          duration: springEnterDuration,
          easing: easings.easeInBack
        }
      })
    }
  }, [content])

  /**
   * On color props change :
   *  - set colorWithTransparency state by calling getColorWithTransparency method with a transparency of 0.5
   */
  useEffect(() => {
    color && setColorWithTransparency(getColorWithTransparency(color, 0.5))
  }, [color])

  const handleClose = (): void => {
    api.start({
      from: {
        transform: 'translateX(0%)'
      },
      to: {
        transform: 'translateX(-100%)'
      },
      config: {
        duration: springLeaveDuration,
        easing: easings.easeOutExpo
      }
    })

    if (timerRef) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        setContent(undefined)
        setRetorikEvent(RetorikEvent.DetailViewClose)
      }, springLeaveDuration)
    }
  }

  return content ? (
    <Template
      handleClose={handleClose}
      className='rf-z-detailedPOI'
      closeButtonClassName='rf-z-util'
      remoteCloseButtonZIndex='rf-z-detailedPOI'
      animation={spring}
    >
      {/* Window containing data */}
      <div className='rf-relative rf-flex rf-flex-row vertical:rf-flex-col large:rf-h-full'>
        <div className='rf-px-6 large:rf-px-8 rf-w-2/3 vertical:rf-w-full rf-mb-4 large:rf-mb-0 rf-flex rf-flex-col rf-border-r rf-border-r-silver vertical:rf-border-r-0'>
          {/* Title + category */}
          <DetailedTitle
            category={content.category}
            title={content.title}
            infoBanner={content.infoBanner}
            color={color}
            customerReviews={content.customerReviews}
            favorite={content.favorite}
            handleClose={handleClose}
            setTitleHeight={setTitleHeight}
          />
          <div className='rf-flex rf-flex-col large:rf-flex-row desktop:rf-h-full'>
            {/* Text + other data */}
            <DetailedText
              text={content.text}
              otherInformations={content.otherInformations}
              color={color}
              url={content.downloadUri}
              titleHeight={titleHeight}
            />
            {/* Media + comment if there is at least one media or comment */}
            {((content.media && content.media.length > 0) ||
              content.review) && (
              <DetailedMedia
                media={content.media}
                review={content.review}
                color={color}
              />
            )}
          </div>
        </div>
        <DetailedAddress
          address={content.addressStructured}
          phoneNumber={content.phoneNumber}
          coordinates={content.coordinates}
          color={color}
          colorWithTransparency={colorWithTransparency}
          customerReviews={content.customerReviews}
          url={content.downloadUri}
        />
        {isMobile && (
          <div className={mode === Mode.text ? 'rf-mb-28' : 'rf-mb-16'}>
            {content.downloadUri && (
              <DownloadSheet
                className='rf-justify-center'
                url={content.downloadUri}
              />
            )}
          </div>
        )}
      </div>
    </Template>
  ) : (
    <React.Fragment />
  )
}

export default AttachmentDetails
