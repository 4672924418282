import type { WeatherData, FullWeatherData } from "../models/weatherTypes";
import { openMeteoBefore, openMeteoAfter } from "../models/constants";

interface Position {
  latitude?: number;
  longitude?: number;
}

const checkWeatherData = async (
  position: Position
): Promise<FullWeatherData | null> => {
  if (position?.latitude && position?.longitude) {
    // Create the address to call the open-meteo API
    const fullAddress = `${openMeteoBefore}latitude=${position.latitude}&longitude=${position.longitude}${openMeteoAfter}`;
    // Call the API
    const fetchedData = await fetch(fullAddress)
      .then((result) => {
        return result.json();
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    if (fetchedData) {
      return processFetchedData(fetchedData);
    }
  }

  return null;
};

const processFetchedData = (data): FullWeatherData => {
  const current: WeatherData = {
    weather: data.current_weather.weathercode,
    temperature: {
      unit: data.daily_units.temperature_2m_max || "°C",
      average: data.current_weather.temperature,
    },
    time: data.current_weather.time,
  };

  const prevision: Array<WeatherData> = [];
  // The first item is the current day, we will use the 6 next days
  for (let i = 1; i < 7; i++) {
    const tempWeatherData = {
      day: data.daily.time[i],
      weather: data.daily.weathercode[i],
      temperature: {
        unit: data.daily_units.temperature_2m_max || "°C",
        min: data.daily.temperature_2m_min[i],
        max: data.daily.temperature_2m_max[i],
      },
    };

    prevision.push(tempWeatherData);
  }

  const allData: FullWeatherData = {
    current: { ...current },
    prevision: [...prevision],
  };

  localStorage.setItem("Retorik.Weather.Data", JSON.stringify(allData));

  return allData;
};

export { checkWeatherData };
