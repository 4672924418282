import React, { useEffect, useRef, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/poi.json'
import QRCode from 'qrcode'

interface DownloadQRProps {
  url: string
  setDownloadHeight?: (x: number) => void
}

const DownloadQR = ({
  url,
  setDownloadHeight
}: DownloadQRProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const [qrCodeData, setQrCodeData] = useState<string | undefined>(undefined)
  const QRRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    QRRef?.current &&
      imageRef?.current &&
      setDownloadHeight &&
      setDownloadHeight(QRRef.current.clientHeight)
  })

  useEffect(() => {
    setQrCodeDataAsync()
  }, [])

  const setQrCodeDataAsync = async (): Promise<void> => {
    const qrCodeString = await QRCode.toDataURL(url)
    setQrCodeData(qrCodeString)
  }

  return (
    <div
      ref={QRRef}
      className='rf-w-full rf-flex rf-flex-col rf-items-start large-vertical:rf-items-center'
    >
      {`${translation[locale]?.download || translation['fr-FR'].download} ${
        translation[locale]?.scanQR || translation['fr-FR'].scanQR
      }`}
      {qrCodeData && (
        <div className='large-vertical:rf-mt-4 desktop:rf-scale-75'>
          <img ref={imageRef} src={qrCodeData} alt='QR Code' />
        </div>
      )}
    </div>
  )
}

export default DownloadQR
