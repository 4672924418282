import { RetorikActivity } from '../models/activityTypes'

const createNewQuestionForTimestamp = (
  activity: RetorikActivity
): RetorikActivity => {
  // Get answer's timestamp or create a new one if none present
  const timestamp =
    activity.localTimestamp || activity.timestamp || Date.now().toString()

  const newQuestion: RetorikActivity = {
    id: activity.id as string,
    type: 'message',
    text: '',
    channelData: {
      'webchat:sequence-id': 1
    },
    timestamp: timestamp,
    localTimestamp: timestamp,
    from: {
      id: 'bot',
      role: 'bot'
    }
  }
  return newQuestion
}
export { createNewQuestionForTimestamp }
