import React from 'react'
import type { IconDefaultProps } from '../../models/weatherTypes'

const DrizzleIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      className={className || 'rf-h-16 rf-w-16'}
      xmlSpace='preserve'
      fill={color || '#1999B1'}
    >
      <path
        d='M3.1,21.04c0-0.24,0.08-0.45,0.25-0.61s0.38-0.24,0.63-0.24h8.97c0.24,0,0.43,0.08,0.59,0.24c0.16,0.16,0.23,0.36,0.23,0.61
	c0,0.24-0.08,0.44-0.24,0.6c-0.16,0.16-0.35,0.24-0.59,0.24H3.98c-0.25,0-0.46-0.08-0.63-0.24S3.1,21.27,3.1,21.04z M5.73,17.98
	c0-0.24,0.09-0.44,0.27-0.6c0.14-0.15,0.34-0.23,0.59-0.23h9c0.23,0,0.42,0.08,0.58,0.23s0.23,0.35,0.23,0.59
	c0,0.24-0.08,0.44-0.23,0.61c-0.15,0.17-0.35,0.25-0.58,0.25h-9c-0.23,0-0.43-0.09-0.6-0.26S5.73,18.21,5.73,17.98z M6.35,15.65
	c0,0.09,0.06,0.14,0.17,0.14h1.43c0.09,0,0.17-0.05,0.23-0.14c0.23-0.54,0.57-0.99,1.04-1.35s0.99-0.56,1.58-0.6l0.54-0.07
	c0.11,0,0.17-0.06,0.17-0.18l0.07-0.52c0.11-1.09,0.58-1.99,1.39-2.72c0.82-0.73,1.77-1.09,2.87-1.09c1.09,0,2.03,0.36,2.83,1.07
	c0.8,0.72,1.27,1.62,1.41,2.7l0.07,0.59c0,0.11,0.06,0.16,0.18,0.16h1.6c0.91,0,1.68,0.32,2.32,0.96c0.64,0.64,0.96,1.41,0.96,2.32
	c0,0.88-0.33,1.64-0.97,2.28c-0.65,0.65-1.42,0.97-2.31,0.97h-6.89c-0.12,0-0.18,0.06-0.18,0.17v1.34c0,0.12,0.06,0.18,0.18,0.18
	h6.89c0.68,0,1.32-0.13,1.94-0.39s1.14-0.61,1.58-1.05s0.79-0.97,1.05-1.58s0.39-1.25,0.39-1.92c0-0.9-0.22-1.73-0.66-2.49
	c-0.44-0.76-1.04-1.36-1.8-1.8c-0.76-0.44-1.6-0.66-2.5-0.66h-0.31c-0.33-1.34-1.03-2.44-2.1-3.3c-1.08-0.85-2.3-1.28-3.68-1.28
	c-1.42,0-2.67,0.44-3.76,1.33c-1.09,0.88-1.78,2.01-2.08,3.39c-0.86,0.19-1.62,0.6-2.27,1.21s-1.1,1.35-1.36,2.22v0.02
	C6.36,15.6,6.35,15.62,6.35,15.65z M7.5,24.13c0-0.24,0.09-0.44,0.26-0.6c0.15-0.16,0.35-0.23,0.59-0.23h8.99
	c0.24,0,0.45,0.08,0.61,0.24c0.17,0.16,0.25,0.36,0.25,0.6c0,0.24-0.08,0.44-0.25,0.61c-0.17,0.17-0.37,0.25-0.61,0.25H8.35
	c-0.23,0-0.43-0.08-0.6-0.25C7.58,24.57,7.5,24.37,7.5,24.13z'
      />
    </svg>
  )
}

export default DrizzleIcon
