{
  "fr-FR": {
    "download": "Télécharger",
    "goto": "Accéder à l'adresse",
    "click": "Cliquez-ici",
    "scanQR": "en scannant le QrCode"
  },
  "en-US": {
    "download": "Download",
    "goto": "Go to address",
    "click": "Click here",
    "scanQR": "by scanning the QrCode"
  },
  "es-ES": {
    "download": "Descargar",
    "goto": "Ir a la dirección",
    "click": "Haga clic aquí",
    "scanQR": "escaneando el QrCode"
  },
  "de-DE": {
    "download": "Herunterladen",
    "goto": "Gehe zu Adresse",
    "click": "Hier klicken",
    "scanQR": "durch Scannen des QrCodes"
  }
}
