import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useSpeech } from '../Contexts/SpeechContext'
import { Mode } from '../../models/enums'

interface SubtitleProps {
  isSpeaking: boolean
  elapsedTime: number
}

interface Sentence {
  sentence: string
  startTime: number
}

const Subtitle = ({
  isSpeaking,
  elapsedTime
}: SubtitleProps): JSX.Element | null => {
  const { displaySubtitles, mode } = useRetorik()
  const { boundaryData } = useSpeech()

  const [sentenceToDisplay, setSentenceToDisplay] = useState<string>('')
  const keepLastSentenceRef = useRef<NodeJS.Timeout | null>(null)

  // Generate an array of sentences from boundaryData
  const sentences: Array<Sentence> = useMemo(() => {
    // If no boundary data is available, return an empty array
    if (!boundaryData || boundaryData.length === 0) {
      return []
    }

    // Prepare an array to store sentences and their durations
    const sentencesArray: Array<Sentence> = []
    let currentSentence = ''
    let currentStartTime = boundaryData[0].startTime / 10000

    // Loop over each boundary data item
    for (let i = 0; i < boundaryData.length; i++) {
      const boundary = boundaryData[i]

      // Prepare a string based on the word at the boundary
      let boundaryString = ''
      if (['.', ',', '...'].includes(boundary.word)) {
        boundaryString = boundary.word
      } else if ([':', ';', '!', '?'].includes(boundary.word)) {
        boundaryString = '\u00A0' + boundary.word
      } else {
        boundaryString = i === 0 ? boundary.word : ' ' + boundary.word
      }

      // Construct a new sentence
      const newSentence = currentSentence + boundaryString

      // If it's the last boundary of the current array or the end of the boundaries (type 'EndBoundary')
      if (
        i === boundaryData.length - 1 ||
        boundary.boundaryType === 'EndBoundary'
      ) {
        // If there is a sentence to push, add it to the array
        if (newSentence.trim() !== '') {
          sentencesArray.push({
            sentence: newSentence,
            startTime: currentStartTime
          })
        }
      } else if (newSentence.length > 50) {
        // If the current word is a punctuation, add it even if it goes beyond 50 characters
        if (boundary.boundaryType === 'PunctuationBoundary') {
          if (newSentence.trim() !== '') {
            sentencesArray.push({
              sentence: newSentence,
              startTime: currentStartTime
            })
          }

          currentStartTime = boundaryData[i + 1].startTime / 10000
          currentSentence = ''
        } else {
          if (newSentence.trim() !== '') {
            sentencesArray.push({
              sentence: currentSentence,
              startTime: currentStartTime
            })
          }

          currentStartTime = boundary.startTime / 10000
          currentSentence = boundary.word
        }
      } else {
        // Continue the current sentence
        currentSentence = newSentence
      }
    }

    return sentencesArray
  }, [boundaryData])

  useEffect(() => {
    if (elapsedTime) {
      for (let i = sentences.length - 1; i >= 0; i--) {
        if (sentences[i].startTime < elapsedTime) {
          setSentenceToDisplay(sentences[i].sentence)
          break
        }
      }
    }
  }, [elapsedTime])

  useEffect(() => {
    keepLastSentenceRef.current && clearTimeout(keepLastSentenceRef.current)

    if (!isSpeaking && sentenceToDisplay && keepLastSentenceRef) {
      keepLastSentenceRef.current = setTimeout(() => {
        setSentenceToDisplay('')
      }, 1500)
    }

    return () => {
      keepLastSentenceRef.current && clearTimeout(keepLastSentenceRef.current)
    }
  }, [isSpeaking])

  return mode === Mode.vocal && sentenceToDisplay && displaySubtitles ? (
    <div className='rf-relative rf-col-start-1 vertical:rf-col-span-full rf-col-span-6 rf-row-start-9 rf-row-span-full vertical:rf-row-start-11 vertical:rf-row-span-1 rf-justify-self-center rf-self-center rf-overflow-y-auto rf-scrollbar-thin rf-w-1/2 vertical:rf-w-5/6 rf-max-h-full rf-py-1 large:rf-py-4'>
      <div
        className='rf-relative rf-max-h-full rf-bg-vocalModeSubtitlesBackground rf-text-vocalModeSubtitlesText rf-justify-self-center rf-self-center rf-p-2 large:rf-p-4 rf-text-size-auto rf-text-center'
        id='retorik-subtitles'
      >
        {sentenceToDisplay}
      </div>
    </div>
  ) : null
}

export default Subtitle
