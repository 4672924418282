{
  "fr-FR": {
    "comment": "avis clients",
    "route": "itinéraire",
    "nodata": "Aucun contenu ne correspond à votre recherche",
    "précédent": "Page précédente",
    "suivant": "Voir la suite",
    "download": "Télécharger cette fiche",
    "scanQR": "en scannant le QrCode"
  },
  "en-US": {
    "comment": "customer review",
    "route": "route",
    "nodata": "No content corresponds to your search",
    "précédent": "Previous page",
    "suivant": "Next page",
    "download": "Download this sheet",
    "scanQR": "by scanning the QrCode"
  },
  "es-ES": {
    "comment": "revisión del cliente",
    "route": "ruta",
    "nodata": "Ningún contenido corresponde a su búsqueda",
    "précédent": "Página anterior",
    "suivant": "Página siguiente",
    "download": "Descargue esta hoja",
    "scanQR": "escaneando el QrCode"
  },
  "de-DE": {
    "comment": "Kundenbewertung",
    "route": "Route",
    "nodata": "Kein Inhalt entspricht Ihrer Suche",
    "précédent": "Vorherige Seite",
    "suivant": "Nächste Seite",
    "download": "Dieses Blatt herunterladen",
    "scanQR": "durch Scannen des QrCodes"
  }
}
