import React, { useEffect, useState, useRef } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { useList } from '../Contexts/ListContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { useUtilsStore, setLastListActivity } from '../Contexts/utilsStore'
import { Mode } from '../../models/enums'
import Template from '../Templates/FullScreenRemoteClosing'
import Title from './Parts/Title'
import FilterLarge from './Parts/FilterLarge'
import FilterMobile from './Parts/FilterMobile'
import ChosenItem from './Parts/Filter/ChosenItem'
import ListContainer from './Parts/ListContainer'
import Suggestions from './Parts/Suggestions'
import { useSpring, easings } from '@react-spring/web'

const springEnterDuration = 1500
const springLeaveDuration = 2500

const List = ({ activity, onClose }): JSX.Element => {
  const { mode } = useRetorik()
  const { isMobile } = useView()
  const { content, filters, selectedFilters, setContent } = useList()
  const { locale } = useLocaleStore()
  const { lastListActivity } = useUtilsStore()
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState<boolean>(false)
  const [largeFilterOpenedKey, setLargeFilterOpenedKey] = useState<
    number | undefined
  >(undefined)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [spring, api] = useSpring(() => ({
    from: {
      transform: 'translateX(0%)'
    }
  }))
  const timerRef = useRef<NodeJS.Timer | null>(null)

  /**
   * On call :
   *  - set ListContext's content state to undefined
   */
  const handleClose = (): void => {
    api.start({
      from: {
        transform: 'translateX(0%)'
      },
      to: {
        transform: 'translateX(-100%)'
      },
      config: {
        duration: springLeaveDuration,
        easing: easings.easeOutExpo
      }
    })

    if (timerRef) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        onClose && onClose()
        setContent(undefined)
      }, springLeaveDuration)
    }
  }

  /**
   * On activity prop change :
   *  - check if the activity has already been displayed or not, using utilsStore's lastListActivity state
   *  - if the activity hasn't been displayed yet, set ListContext's content state with the activity
   * On component unmount :
   *  - set utilsStore's lastListActivity state to the current activity's id
   */
  useEffect(() => {
    activity && activity.id !== lastListActivity && setContent(activity)

    return (): void => {
      activity?.id && setLastListActivity(activity.id)
    }
  }, [activity])

  /**
   * On call :
   *  - scroll to the scrollRef HTML element at the top of the view
   */
  const scrollToRef = (): void => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  /**
   * On content change :
   *  - call scrollToRef method
   */
  useEffect(() => {
    const timer =
      content && isMobile
        ? setTimeout(() => {
            scrollToRef()
          }, 100)
        : null

    return (): void => {
      timer && clearTimeout(timer)
    }
  }, [content])

  useEffect(() => {
    api.start({
      from: {
        transform: 'translateX(-100%)'
      },
      to: {
        transform: 'translateX(0%)'
      },
      config: {
        duration: springEnterDuration,
        easing: easings.easeInBack
      }
    })

    return (): void => {
      timerRef?.current && clearTimeout(timerRef.current)
    }
  }, [])

  return content ? (
    <Template
      className='large:rf-px-16 rf-flex rf-flex-col large:rf-h-full'
      handleClose={handleClose}
      onClick={
        isMobile
          ? (): void => {}
          : (): void => setLargeFilterOpenedKey(undefined)
      }
      remoteCloseButtonZIndex='rf-z-util'
      animation={spring}
    >
      {isMobile && filters.length > 0 && (
        <FilterMobile
          setFiltersOpened={setMobileFiltersOpened}
          handleClose={handleClose}
        />
      )}

      <div className='rf-w-full rf-overflow-y-scroll rf-scrollbar-hidden'>
        <div ref={scrollRef} />
        <div
          className={`${
            mobileFiltersOpened ? 'rf-hidden' : 'rf-flex'
          } rf-flex-col rf-items-start large:rf-pt-16 large:rf-h-full large-vertical:rf-pt-[20vh]`}
        >
          {content.text && (
            <Title
              className='rf-ml-2 rf-mt-8 large:rf-ml-0 large:rf-mt-0'
              title={capitalizeFirstLetter(content.text, locale)}
              numberOfResults={content.channelData?.numberOfResults || 0}
            />
          )}

          {!isMobile && filters.length > 0 && (
            <FilterLarge
              openedFilterKey={largeFilterOpenedKey}
              setOpenedFilterKey={setLargeFilterOpenedKey}
            />
          )}

          {isMobile && (
            <div className='rf-flex rf-flex-row rf-mx-2 rf-gap-2 rf-mb-2 rf-flex-wrap'>
              {selectedFilters?.map((chosenFilter, key1) => {
                return chosenFilter.items.map((name, key2) => {
                  return (
                    <ChosenItem
                      className='rf-px-2'
                      key={`${key1}-${key2}`}
                      name={name}
                      parent={{
                        id: chosenFilter.id,
                        title: chosenFilter.title
                      }}
                    />
                  )
                })
              })}
            </div>
          )}

          <ListContainer
            hasFilterOrSuggestedAction={
              filters.length > 0 ||
              content.suggestedActions?.actions?.length > 0
            }
          />

          {content.suggestedActions?.actions &&
            content.suggestedActions.actions.length > 0 && (
              <Suggestions
                className={`rf-px-2 ${
                  mode === Mode.text ? 'rf-mb-28' : 'rf-mb-16'
                } large:rf-px-0 large:rf-mb-4`}
                suggestions={content.suggestedActions.actions}
                handleClose={handleClose}
              />
            )}
        </div>
      </div>
    </Template>
  ) : (
    <React.Fragment />
  )
}

export default List
