import React, { useEffect, useState } from 'react'
import { useView } from '../../Contexts/ViewContext'
import { DEFAULT_VIEWS } from '../../../models/constants'
import DisplayGenerator from './DisplayGenerator'
import { useRetorik } from '../../Contexts/RetorikContext'
import type { BaseMenu, CustomMenu } from '../../../models/types'

interface DisplayViewsProps {
  handleClick?: () => void
  conditionalHandleClick?: () => void
}

const DisplayViews = ({
  handleClick,
  conditionalHandleClick
}: DisplayViewsProps): JSX.Element => {
  const { currentDeviceType, isMobile } = useView()
  const { configuration } = useRetorik()
  const [finalMenu, setFinalMenu] = useState<Array<Array<JSX.Element>>>([])

  const buildMenu = (
    menu: Array<BaseMenu | CustomMenu>
  ): Array<JSX.Element> => {
    let fullMenu: Array<JSX.Element> = []
    menu.forEach((currentMenu) => {
      // If the menu has 'icon' as key, it's a CustomMenu. Otherwise it's a BaseMenu
      if (Object.keys(currentMenu).includes('icon')) {
        const customCurrentMenu = currentMenu as CustomMenu
        fullMenu.push(
          <DisplayGenerator
            type='CustomMenu'
            indice={customCurrentMenu.indice}
            label={customCurrentMenu.label}
            icon={customCurrentMenu.icon}
            device={currentDeviceType}
            handleClick={(): void => {
              if (customCurrentMenu.clickHandler) {
                customCurrentMenu.clickHandler()
              }
              handleClick && handleClick()
            }}
            customDisplay={customCurrentMenu.customDisplay}
            conditionalHandleClick={
              customCurrentMenu.closeParametersOnClickInMobileMode
                ? conditionalHandleClick
                : undefined
            }
            name={customCurrentMenu.name}
          />
        )
      } else {
        fullMenu.push(
          <DisplayGenerator
            type='BaseMenu'
            indice={currentMenu.indice}
            view={(currentMenu as BaseMenu).view}
            device={currentDeviceType}
            handleClick={handleClick}
            conditionalHandleClick={conditionalHandleClick}
          />
        )
      }
    })

    fullMenu = fullMenu.sort(
      (a: any, b: any) => a.props.indice - b.props.indice
    )

    return fullMenu
  }

  useEffect(() => {
    const tempFinalMenu: Array<Array<JSX.Element>> = []
    // Basic menu
    tempFinalMenu.push(
      buildMenu(
        configuration && configuration.baseMenu
          ? [...configuration.baseMenu]
          : [...DEFAULT_VIEWS]
      )
    )

    // Custom menus
    if (
      configuration &&
      configuration.customMenu &&
      configuration.customMenu.length > 0
    ) {
      if (Array.isArray(configuration.customMenu[0])) {
        configuration.customMenu.forEach((menuArray) => {
          tempFinalMenu.push(buildMenu(menuArray))
        })
      } else {
        tempFinalMenu.push(
          buildMenu(configuration.customMenu as Array<CustomMenu>)
        )
      }
    }

    setFinalMenu(tempFinalMenu)
  }, [])

  return (
    <React.Fragment>
      {finalMenu.map((line: Array<JSX.Element>, key) => {
        return isMobile ? (
          <div
            key={key}
            className={`${
              key === 0 && 'rf-pb-4'
            } rf-flex rf-flex-col rf-items-center`}
          >
            {/* Separation line between menu containers */}
            {key !== 0 && (
              <div className='rf-h-6 -rf-mx-6 rf-width-fill-available rf-bg-[#F0F0F0] rf-border-y rf-border-y-menuBorder' />
            )}
            {line.map((e: JSX.Element, subkey) => {
              return <React.Fragment key={subkey}>{e}</React.Fragment>
            })}
          </div>
        ) : (
          <div
            key={key}
            className='rf-flex rf-flex-row rf-items-center rf-flex-wrap rf-gap-8'
          >
            {line.map((e: JSX.Element, subkey) => {
              return <React.Fragment key={subkey}>{e}</React.Fragment>
            })}
          </div>
        )
      })}
    </React.Fragment>
  )
}

export default DisplayViews
