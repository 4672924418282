{
  "fr-FR": {
    "choose": "Choisissez une réponse",
    "submit": "valider"
  },
  "en-US": {
    "choose": "Choose an answer",
    "submit": "submit"
  },
  "es-ES": {
    "choose": "Elija una respuesta",
    "submit": "enviar"
  },
  "de-DE": {
    "choose": "Wählen Sie eine Antwort",
    "submit": "einreichen"
  }
}
