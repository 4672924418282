import React from 'react'
import type { TemplateDefaultProps } from '../../models/types'
import { animated } from '@react-spring/web'

const FullScreenRemote = ({
  className,
  background,
  onClick,
  animation,
  children
}: TemplateDefaultProps): JSX.Element => {
  return (
    <animated.div
      className={`rf-z-util rf-relative rf-overflow-y-scroll rf-scrollbar-hidden rf-text-black rf-text-size-auto rf-bg-templateBackground large:rf-mr-30 rf-col-span-full rf-row-span-full ${className}`}
      style={{
        background: background || '',
        outline: '1px solid transparent',
        ...animation
      }}
      onClick={onClick ? (): void => onClick() : (): void => {}}
    >
      {children}
    </animated.div>
  )
}

export default FullScreenRemote
