{
  "_.comment": "The content of this file is contributed by the community.",
  "ACTIVITY_BOT_SAID": "رد الآلي $1 قال, $2 أرسل ب: $3",
  "ACTIVITY_ERROR_BOX_TITLE": "رسالة المشكلة",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "فشل في الارسال. [إعادة المحوالة][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "جاري الإرسال",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "أرسل ب: $1",
  "ACTIVITY_STATUS_SEND_STATUS_ALT": "حالة الإرسال: $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "الآن",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "الآن",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "الآن",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "الآن",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "الآن",
  "ACTIVITY_USER_SAID": "المستخدم $1 قال, $2 أرسل ب: $3",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "غير قادر على تفصيل بطاقة التكيف",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "غير قادر على عرض بطاقة التكيف",
  "CAROUSEL_ATTACHMENTS_BOT_ALT": "الرد الآلي أرسل: ",
  "CAROUSEL_ATTACHMENTS_USER_ALT": "المستخدم أرسل: ",
  "CAROUSEL_FLIPPER_LEFT_ALT": "يسار",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "يمين",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "تم الإتصال",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "يتم الاتصال…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "غير قادر عالإتصال.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "حدث انقطاع في الشبكة. إعادة الاتصال…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "خطأ تقديم. يرجى التحقق من وحدة التحكم أو الاتصال مطور الروبوت.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "الإتصال يستغرق اكثر من المعتاد.",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "تحميل الملف '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "تحميل الملف $1 بحجم $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "بحجم $1",
  "RECEIPT_CARD_TAX": "ضريبة",
  "RECEIPT_CARD_TOTAL": "المجموع",
  "RECEIPT_CARD_VAT": "VAT",
  "SPEECH_INPUT_LISTENING": "جاري الإستماع…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "المايكروفون مغلق",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "المايكروفون يعمل",
  "SPEECH_INPUT_STARTING": "جاري البدء…",
  "TEXT_INPUT_ALT": "صندوق الإرسال",
  "TEXT_INPUT_PLACEHOLDER": "اكتب رسالتك",
  "TEXT_INPUT_SEND_BUTTON_ALT": "إرسال",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "تكلم",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "رفع الملف",
  "TRANSCRIPT_NEW_MESSAGES": "رسائل جديدة",
  "TYPING_INDICATOR_ALT": "يتم عرض مؤشر الكتابة"
}
