import React from 'react'
import type { IconDefaultProps } from '../../models/weatherTypes'

const DustIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      className={className || 'rf-h-16 rf-w-16'}
      xmlSpace='preserve'
      fill={color || '#1999B1'}
    >
      <path
        d='M7.33,16.58c0-0.23,0.08-0.41,0.23-0.56c0.16-0.15,0.37-0.22,0.64-0.22h5.71c0.27,0,0.48,0.07,0.64,0.22
	c0.16,0.15,0.23,0.33,0.23,0.56c0,0.27-0.08,0.49-0.23,0.64s-0.37,0.23-0.64,0.23H8.2c-0.27,0-0.48-0.08-0.64-0.23
	S7.33,16.86,7.33,16.58z M7.33,10.67c0-0.22,0.08-0.41,0.23-0.55C7.72,9.97,7.93,9.9,8.2,9.9h2.96c0.27,0,0.48,0.07,0.64,0.22
	c0.16,0.14,0.24,0.33,0.24,0.55c0,0.27-0.08,0.48-0.24,0.64c-0.16,0.16-0.37,0.24-0.64,0.24H8.2c-0.27,0-0.48-0.08-0.64-0.23
	S7.33,10.94,7.33,10.67z M8.32,19.54c0-0.22,0.09-0.42,0.28-0.6c0.18-0.18,0.39-0.28,0.6-0.28c0.26,0,0.46,0.09,0.62,0.27
	s0.24,0.38,0.24,0.61c0,0.27-0.08,0.49-0.23,0.65c-0.15,0.16-0.36,0.23-0.63,0.23c-0.23,0-0.44-0.08-0.61-0.24
	C8.4,20.01,8.32,19.8,8.32,19.54z M9.74,13.61c0-0.23,0.07-0.44,0.22-0.61c0.15-0.18,0.33-0.27,0.54-0.27
	c0.26,0,0.48,0.09,0.64,0.27c0.16,0.18,0.24,0.38,0.24,0.61c0,0.27-0.08,0.49-0.23,0.65c-0.16,0.16-0.37,0.23-0.65,0.23
	c-0.23,0-0.41-0.08-0.55-0.24S9.74,13.88,9.74,13.61z M10.73,19.54c0-0.23,0.08-0.44,0.24-0.61s0.38-0.27,0.64-0.27h3.83l0.88,0.88
	c0,0.26-0.09,0.47-0.27,0.64s-0.38,0.24-0.61,0.24h-3.83c-0.27,0-0.49-0.08-0.65-0.24S10.73,19.81,10.73,19.54z M12.05,13.61
	c0-0.22,0.09-0.42,0.28-0.6c0.18-0.18,0.39-0.28,0.6-0.28h3.83c0.26,0,0.47,0.09,0.63,0.27c0.16,0.18,0.24,0.38,0.24,0.61
	c0,0.27-0.08,0.49-0.23,0.65c-0.16,0.16-0.37,0.23-0.64,0.23h-3.83c-0.23,0-0.44-0.08-0.61-0.24
	C12.14,14.09,12.05,13.88,12.05,13.61z M12.81,10.67c0-0.22,0.08-0.41,0.24-0.55c0.16-0.14,0.37-0.22,0.64-0.22h5.71
	c0.23,0,0.43,0.08,0.61,0.23c0.18,0.15,0.27,0.33,0.27,0.54c0,0.26-0.09,0.48-0.27,0.64c-0.18,0.16-0.38,0.24-0.61,0.24h-5.71
	c-0.27,0-0.49-0.08-0.65-0.24C12.88,11.15,12.81,10.94,12.81,10.67z M15.44,16.58c0-0.21,0.09-0.4,0.27-0.55
	c0.18-0.15,0.38-0.23,0.61-0.23s0.43,0.08,0.61,0.23c0.18,0.15,0.27,0.34,0.27,0.55c0,0.26-0.09,0.47-0.27,0.63
	c-0.18,0.16-0.38,0.24-0.61,0.24c-0.23,0-0.44-0.08-0.61-0.24C15.53,17.06,15.44,16.85,15.44,16.58z M16.98,19.54
	c0-0.23,0.08-0.44,0.24-0.61c0.16-0.18,0.37-0.27,0.63-0.27h1.87c0.26,0,0.47,0.09,0.63,0.26c0.16,0.17,0.24,0.38,0.24,0.62
	c0,0.27-0.08,0.49-0.23,0.65c-0.15,0.16-0.37,0.23-0.64,0.23h-1.87c-0.27,0-0.48-0.08-0.64-0.23
	C17.06,20.03,16.98,19.81,16.98,19.54z M17.85,16.58c0-0.21,0.09-0.4,0.27-0.55c0.18-0.15,0.38-0.23,0.61-0.23h3.07
	c0.22,0,0.4,0.08,0.54,0.23c0.14,0.15,0.22,0.33,0.22,0.55c0,0.27-0.07,0.48-0.21,0.64c-0.14,0.16-0.32,0.23-0.55,0.23h-3.07
	c-0.23,0-0.44-0.08-0.61-0.24C17.94,17.06,17.85,16.85,17.85,16.58z M18.29,13.61c0-0.22,0.09-0.42,0.28-0.6
	c0.18-0.18,0.39-0.28,0.6-0.28h1.96c0.21,0,0.39,0.09,0.54,0.27c0.15,0.18,0.23,0.38,0.23,0.61c0,0.27-0.07,0.48-0.22,0.64
	c-0.14,0.16-0.33,0.24-0.55,0.24h-1.96c-0.23,0-0.44-0.08-0.61-0.24C18.38,14.09,18.29,13.88,18.29,13.61z M21.03,10.67
	c0-0.22,0.07-0.4,0.22-0.55C21.4,9.97,21.58,9.9,21.8,9.9c0.27,0,0.48,0.07,0.64,0.22c0.16,0.14,0.24,0.33,0.24,0.55
	c0,0.27-0.08,0.48-0.24,0.64c-0.16,0.16-0.37,0.24-0.64,0.24c-0.23,0-0.41-0.08-0.55-0.24C21.1,11.15,21.03,10.94,21.03,10.67z'
      />
    </svg>
  )
}

export default DustIcon
