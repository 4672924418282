{
  "_.comment": "The content of this file is contributed by the community.",
  "ACTIVITY_BOT_SAID": "البوت $1 قال, $2 أرسل: $3",
  "ACTIVITY_ERROR_BOX_TITLE": "رسالة خطأ",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "[إعادة المحاولة][RETRY]. فشل في الإرسال.",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "أرسال",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "أرسل: $1",
  "ACTIVITY_STATUS_SEND_STATUS_ALT": "ارسل الحالة: $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "حالا",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "حالا",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "حالا",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "حالا",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "حالا",
  "ACTIVITY_USER_SAID": "المستخدم $1 قال, $2 أرسل: $3",
  "CAROUSEL_ATTACHMENTS_BOT_ALT": "البوت ارسل:",
  "CAROUSEL_ATTACHMENTS_USER_ALT": "المستخدم أرسل: ",
  "CAROUSEL_FLIPPER_LEFT_ALT": "يسار",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "يمين",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "متصل",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "يتم التوصيل…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "غير قادر على الاتصال.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "حدث انقطاع في الشبكة. إعادة التوصيل…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "خطأ في التقديم. يرجى التحقق من وحدة التحكم أو الاتصال بمطور البوت.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "يستغرق وقتا أطول من المعتاد للاتصال.",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "تحميل الملف '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "تحميل ملف تحميل الملف $1 بحجم $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 بحجم $2",
  "RECEIPT_CARD_TAX": "ضريبة",
  "RECEIPT_CARD_TOTAL": "المجموع",
  "RECEIPT_CARD_VAT": "ضريبة القيمة المضافة",
  "SPEECH_INPUT_LISTENING": "يستمع…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "الميكروفون مغلق",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "الميكروفون قيد التشغيل",
  "SPEECH_INPUT_STARTING": "يبدأ…",
  "TEXT_INPUT_PLACEHOLDER": "اكتب رسالتك",
  "TEXT_INPUT_SEND_BUTTON_ALT": "أرسل",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "تكلم",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "رفع الملف",
  "TRANSCRIPT_NEW_MESSAGES": "رسائل جديدة",
  "TYPING_INDICATOR_ALT": "عرض مؤشر الكتابة"
}
