{
  "fr-FR": {
    "1": "accueil",
    "2": "chaîne info live",
    "3": "météo",
    "4": "numéros d'urgence",
    "1000": "historique",
    "1001": "tutoriel"
  },
  "en-US": {
    "1": "home",
    "2": "live info channel",
    "3": "weather",
    "4": "emergency numbers",
    "1000": "history",
    "1001": "tutorial"
  },
  "es-ES": {
    "1": "inicio",
    "2": "canal de información en directo",
    "3": "tiempo",
    "4": "números de emergencia",
    "1000": "historia",
    "1001": "tutorial"
  },
  "de-DE": {
    "1": "Startseite",
    "2": "Live-Infokanal",
    "3": "Wetter",
    "4": "Notrufnummern",
    "1000": "Geschichte",
    "1001": "anleitung"
  }
}
