import React, { useEffect, useState, useRef } from 'react'
import type { ChatbotData, Queue } from '../../models/types'
import ChatbotProvider from '@davi-ai/body-engine-sprite'
import { useRetorik } from '../Contexts/RetorikContext'
import { useSpeech } from '../Contexts/SpeechContext'
import useAnimationQueue from '../../hooks/useAnimationQueue'
import Subtitle from './Subtitle'

interface ChatbotAndSubtitlesContainerProps {
  className?: string
  chatbotData?: ChatbotData
  showSubtitles?: boolean
}

const intervalInMilliseconds = 100

const ChatbotAndSubtitlesContainer = ({
  className,
  chatbotData,
  showSubtitles
}: ChatbotAndSubtitlesContainerProps): JSX.Element => {
  const { agentData } = useRetorik()
  const { speaking, boundaryData } = useSpeech()
  const [speechIntervals, setSpeechIntervals] = useState<Array<number>>([])
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false)
  const [elapsedTime, setElapsedTime] = useState<number>(0)
  const timerRef = useRef<any>(null)

  const lastAnimationQueue = useAnimationQueue()
  const [animationQueue, setAnimationQueue] = useState<Queue>([])

  useEffect(() => {
    lastAnimationQueue && setAnimationQueue(lastAnimationQueue)
  }, [lastAnimationQueue])

  useEffect(() => {
    if (speaking && timerRef) {
      timerRef.current && clearInterval(timerRef.current)
      const now = Date.now()
      timerRef.current = setInterval(() => {
        setElapsedTime(Date.now() - now)
      }, intervalInMilliseconds)
    } else {
      timerRef?.current && clearInterval(timerRef.current)
      setElapsedTime(0)
    }

    return () => {
      timerRef?.current && clearInterval(timerRef.current)
    }
  }, [speaking])

  useEffect(() => {
    if (boundaryData.length > 0) {
      const tempArray: Array<number> = []

      /* This is the right thing to do, but animations can't keep up with too many starts and stops, so it isn't usable ATM
      boundaryData.forEach((boundary) => {
        if (boundary.boundaryType === 'WordBoundary') {
          if (tempArray.length > 0) {
            if (
              boundary.startTime / 10000 - tempArray[tempArray.length - 1] >
              50
            ) {
              tempArray.push(boundary.startTime / 10000)
              tempArray.push(boundary.endTime / 10000)
            } else {
              tempArray.pop()
              tempArray.push(boundary.endTime / 10000)
            }
          } else {
            tempArray.push(boundary.startTime / 10000)
            tempArray.push(boundary.endTime / 10000)
          }
        }
      })

      setSpeechIntervals(tempArray)
      */

      // While animations can't keep up, let's just synchronize the beginning and the end of speech
      const firstWord = boundaryData.find(
        (boundary) => boundary.boundaryType === 'WordBoundary'
      )
      const lastWord = boundaryData
        .slice()
        .reverse()
        .find((boundary) => boundary.boundaryType === 'WordBoundary')

      if (firstWord && lastWord) {
        tempArray.push(firstWord.startTime / 10000)
        tempArray.push(lastWord.endTime / 10000)
      }

      setSpeechIntervals(tempArray)
    } else {
      setSpeechIntervals([])
    }
  }, [boundaryData])

  useEffect(() => {
    if (elapsedTime && speechIntervals.length > 0) {
      let inside = false
      for (let i = 0; i < speechIntervals.length; i++) {
        if (speechIntervals[i] > elapsedTime) {
          inside = true
          setIsSpeaking(!(i % 2 === 0))
          break
        }
      }

      !inside && setIsSpeaking(false)
    } else {
      setIsSpeaking(false)
    }
  }, [elapsedTime])

  return agentData ? (
    <React.Fragment>
      <div className={className}>
        <ChatbotProvider
          licence='licence'
          agentData={agentData}
          height={chatbotData?.height}
          size={chatbotData?.size}
          speak={isSpeaking}
          animationQueue={animationQueue}
          setAnimationQueue={setAnimationQueue}
        />
      </div>
      {showSubtitles && (
        <Subtitle isSpeaking={isSpeaking} elapsedTime={elapsedTime} />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default ChatbotAndSubtitlesContainer
