/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import Video from './Video'
import { AgentData, Queue, AnimationData, Size } from '../models/types'

const getRandomInt = (max: number = 1) => {
  return Math.ceil(Math.random() * max)
}

const fps = 40

interface ChatbotProps {
  agentData: AgentData
  height: Size
  size: Size
  speak: boolean
  animationQueue?: Queue
  setAnimationQueue?: any
}

const Chatbot = ({
  agentData,
  height,
  size,
  speak,
  animationQueue,
  setAnimationQueue
}: ChatbotProps): JSX.Element => {
  const [allowSpeechAnimations, setAllowSpeechAnimations] =
    useState<boolean>(true)
  const [speaking, setSpeaking] = useState<boolean>(speak)
  const [moving, setMoving] = useState<boolean>(true)
  const moveNS = useRef<HTMLVideoElement>(null)
  const moveSP = useRef<HTMLVideoElement>(null)
  const stillNS = useRef<HTMLVideoElement>(null)
  const stillSP = useRef<HTMLVideoElement>(null)
  const [currentStill, setCurrentStill] = useState<string>('still1')
  const [currentMove, setCurrentMove] = useState<string>('idle1')
  const [queue, setQueue] = useState<Array<AnimationData>>([])
  const [idleAndExplainFolder, setIdleAndExplainFolder] =
    useState<string>('move')
  const [stillFolder, setStillFolder] = useState<string>('still')
  const [trigger, setTrigger] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(document.hidden)
  const [windowResize, setWindowResize] = useState<boolean>(false)

  const sizeInPxRef = useRef<number | null>()
  const sizeInPx = useMemo<number>(() => {
    if (size) {
      if (typeof size === 'number') {
        return size
      } else {
        if (size.slice(-2).toLowerCase() === 'vh') {
          const num = parseInt(size.substring(0, size.length - 2))
          return isNaN(num)
            ? window.innerHeight
            : (num * window.innerHeight) / 100
        } else if (size.slice(-1) === '%') {
          const num = parseInt(size.substring(0, size.length - 1))
          return isNaN(num)
            ? window.innerHeight
            : (num * window.innerHeight) / 100
        }
      }
    }

    return window.innerHeight
  }, [size, windowResize])

  const canvasWidthRef = useRef<number | null>()
  const canvasWidth = useMemo<number>(() => {
    return sizeInPx > window.innerWidth ? window.innerWidth : sizeInPx
  }, [sizeInPx, windowResize])

  const canvasHeightRef = useRef<number | null>()
  const canvasHeight = useMemo<number>(() => {
    let marginTop = 0
    if (height) {
      if (typeof height === 'number') {
        marginTop = height
      } else {
        if (height.slice(-2).toLowerCase() === 'vh') {
          const num = parseInt(height.substring(0, height.length - 2))
          marginTop = isNaN(num)
            ? 0
            : Math.round((num * window.innerHeight) / 100)
        } else if (height.slice(-1) === '%') {
          const num = parseInt(height.substring(0, height.length - 1))
          marginTop = isNaN(num)
            ? 0
            : Math.round((num * window.innerHeight) / 100)
        }
      }
    }

    return sizeInPx - marginTop > window.innerHeight
      ? window.innerHeight - marginTop
      : sizeInPx - marginTop
  }, [sizeInPx, height, windowResize])

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const imageSize = useMemo(() => {
    const realSize = canvasHeight
    if (realSize < 480) {
      return 'small'
    }
    if (realSize < 1200) {
      return 'medium'
    }
    return 'large'
  }, [size, canvasHeight])

  /**
   * Update refs for sizeInPx, canvasWidth and canvasHeight to use in computeframe method
   */
  useEffect(() => {
    sizeInPx && (sizeInPxRef.current = sizeInPx)
  }, [sizeInPx])

  useEffect(() => {
    canvasWidth && (canvasWidthRef.current = canvasWidth)
  }, [canvasWidth])

  useEffect(() => {
    canvasHeight && (canvasHeightRef.current = canvasHeight)
  }, [canvasHeight])

  // Function to set the minimum between screen height and width to use the right animations
  const detectSize = () => {
    setWindowResize((value) => !value)
  }

  const onVisibilityChange = () => {
    setVisible(document.hidden)
  }

  useEffect(() => {
    if (visible) {
      if (moving) {
        moveNS.current && moveNS.current.pause()
        moveSP.current && moveSP.current.pause()
      } else {
        stillNS.current && stillNS.current.pause()
        stillSP.current && stillSP.current.pause()
      }
    } else {
      if (moving) {
        moveNS.current && moveNS.current.play().catch((e) => console.warn(e))
        moveSP.current && moveSP.current.play().catch((e) => console.warn(e))
      } else {
        stillNS.current && stillNS.current.play().catch((e) => console.warn(e))
        stillSP.current && stillSP.current.play().catch((e) => console.warn(e))
      }
    }
  }, [visible])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false)

    // cleanup this component
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  useEffect(() => {
    setSpeaking(allowSpeechAnimations ? speak : false)
  }, [speak, allowSpeechAnimations])

  const computeFrame = (): void => {
    const canvas = canvasRef.current

    if (
      videoRef.current &&
      sizeInPxRef?.current &&
      canvas &&
      canvasWidthRef?.current &&
      canvasHeightRef?.current
    ) {
      const context = canvas.getContext('2d')
      if (context) {
        const ratio = videoRef.current.videoWidth / sizeInPxRef.current
        // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas#avoid_floating-point_coordinates_and_use_integers_instead
        const left = Math.round(
          (videoRef.current.videoWidth - canvasWidthRef.current * ratio) / 2
        )
        context.clearRect(0, 0, canvasWidthRef.current, canvasHeightRef.current)
        context.drawImage(
          videoRef.current,
          Math.round(left),
          0,
          Math.round(canvasWidthRef.current * ratio),
          Math.round(canvasHeightRef.current * ratio),
          0,
          0,
          canvasWidthRef.current,
          canvasHeightRef.current
        )
      }
    }
  }

  useEffect(() => {
    const timeoutDuration = Math.round(1000 / fps)
    let timer: NodeJS.Timeout
    const computeCallback = (): void => {
      computeFrame()
      timer = setTimeout(() => {
        requestAnimationFrame(computeCallback)
      }, timeoutDuration)
    }

    timer = setTimeout(() => {
      requestAnimationFrame(computeCallback)
    }, timeoutDuration)

    return () => clearTimeout(timer)
  }, [canvasWidth, canvasHeight])

  useEffect(() => {
    if (moving) {
      setCurrentStill('still1')
    } else {
      if (speaking) {
        setCurrentMove('explain1')
      } else {
        setCurrentMove('idle1')
      }
    }
  }, [agentData])

  useEffect(() => {
    if (animationQueue) {
      if (animationQueue.length === 0) {
        setQueue([])
      } else {
        // Verify if the wanted animations shall exist from the data given in the manifest.json file
        const animationDataArray = checkQueue(animationQueue)
        if (animationDataArray.length > 0) {
          setQueue(animationDataArray)
          setTrigger(true)
        }
      }
    }
  }, [animationQueue])

  // Check if each element of the given queue has a match in the data from the manifest.json file loaded
  function checkQueue(queueToCheck: any) {
    var test: boolean = true
    const dataToReturn: Array<AnimationData> = []
    // Get the defined folders from the manifest
    const keys = agentData ? Object.keys(agentData.data) : []
    // Parse each item to get an array with the name of the animation and its index
    queueToCheck.forEach((item: string) => {
      const data: AnimationData = !isNaN(
        parseInt(item.substring(item.length - 2))
      )
        ? [
            item.substring(0, item.length - 2),
            parseInt(item.substring(item.length - 2))
          ]
        : !isNaN(parseInt(item.substring(item.length - 1)))
        ? [
            item.substring(0, item.length - 1),
            parseInt(item.substring(item.length - 1))
          ]
        : [item, -1]

      const lowCase = data[0].toLowerCase()
      // Check if the folder exists in the manifest and if the index of the animation is lower than the max one
      if (
        keys.includes(lowCase) &&
        agentData &&
        agentData.data[lowCase] >= data[1]
      ) {
        dataToReturn.push(data)
      } else {
        console.log('Wrong animation wanted : ', item)
        test = false
      }
    })

    return test ? dataToReturn : []
  }

  // Switch animation when not in a queue
  function switchAnimation(data: 'idle' | 'explain' | 'still') {
    var rand = 0
    switch (data) {
      case 'idle':
        setIdleAndExplainFolder('move')
        rand = getRandomInt(agentData?.data.idle)
        break
      case 'explain':
        setIdleAndExplainFolder('move')
        rand = getRandomInt(agentData?.data.explain)
        break
      case 'still':
        setStillFolder('still')
        rand = getRandomInt(agentData?.data.still)
        break
      default:
        break
    }

    if (data === 'still') {
      if (currentStill === `still${rand}`) {
        rand === 1
          ? setCurrentStill('still2')
          : setCurrentStill(`still${rand - 1}`)
      } else {
        setCurrentStill(`still${rand}`)
      }
    } else {
      if (currentMove === `${data}${rand}`) {
        rand === 1
          ? setCurrentMove(`${data}2`)
          : setCurrentMove(`${data}${rand - 1}`)
      } else {
        setCurrentMove(`${data}${rand}`)
      }
    }
  }

  // Switch animation when in a queue
  function switchQueue(still: boolean) {
    const item = queue[0]
    // Check if we are using an 'Explain' or 'Idle' animation. If so, the folder is 'Move'
    const folder = ['explain', 'idle'].includes(item[0].toLowerCase())
      ? 'move'
      : item[0]
    // Check if an animation index has been found. If not, use a random
    const animationToDisplay =
      item[1] === -1
        ? `${item[0]}${getRandomInt(agentData?.data[item[0].toLowerCase()])}`
        : `${item[0]}${item[1]}`

    if (still) {
      setStillFolder(folder)
      setCurrentStill(animationToDisplay)
    } else {
      setIdleAndExplainFolder(folder)
      setCurrentMove(animationToDisplay)
    }

    const newQueue = [...queue]
    newQueue.splice(0, 1)
    if (newQueue.length === 0) {
      console.log('Send empty queue')
      setAnimationQueue && setAnimationQueue([])
    } else {
      setQueue(newQueue)
    }
  }

  // Handle changes of 'moving' and 'speaking' states
  function handleMovingOrSpeaking(movingChanged: boolean) {
    if (moving === true) {
      // If the 'moving' constant has changed launch moving animations and switch animations.
      // Either way, only change the displayed animation
      if (movingChanged) {
        moveSP.current &&
          moveSP.current.play().catch((e) => {
            e !== null && console.warn(e)
          })
        moveNS.current &&
          moveNS.current.play().catch((e) => {
            e !== null && console.warn(e)
          })
        queue.length > 0 ? switchQueue(true) : switchAnimation('still')
      }

      videoRef.current = speaking ? moveSP.current : moveNS.current
    } else {
      if (movingChanged) {
        stillSP.current &&
          stillSP.current.play().catch((e) => {
            e !== null && console.warn(e)
          })
        stillNS.current &&
          stillNS.current.play().catch((e) => {
            e !== null && console.warn(e)
          })
        if (speaking) {
          queue.length > 0 ? switchQueue(false) : switchAnimation('explain')
        } else {
          queue.length > 0 ? switchQueue(false) : switchAnimation('idle')
        }
      }

      videoRef.current = speaking ? stillSP.current : stillNS.current
    }
  }

  useEffect(() => {
    handleMovingOrSpeaking(true)
  }, [moving])

  useEffect(() => {
    handleMovingOrSpeaking(false)
  }, [speaking])

  // Trigger used to launch the first animation of the queue right after the current one
  useEffect(() => {
    if (trigger) {
      switchQueue(!!moving)
      setTrigger(false)
    }
  }, [trigger])

  // Load move animations when the source is modified
  useEffect(() => {
    try {
      moveNS.current && moveNS.current.load()
      moveSP.current && moveSP.current.load()
    } catch (e) {
      console.warn(e)
    }
  }, [currentMove])

  // Load still animations when the source is modified
  useEffect(() => {
    try {
      stillSP.current && stillSP.current.load()
      stillNS.current && stillNS.current.load()
    } catch (e) {
      console.warn(e)
    }
  }, [currentStill])

  useEffect(() => {
    // Add event listener for screen resize to adjust videos size
    window.addEventListener('resize', detectSize)
    window.addEventListener('preventChatbotSpeakAnimationsEvent', () => {
      setAllowSpeechAnimations(false)
    })
    window.addEventListener('allowChatbotSpeakAnimationsEvent', () => {
      setAllowSpeechAnimations(true)
    })
    window.addEventListener('setSpeaking', (e: CustomEvent) => {
      setSpeaking(e.detail)
    })
    // Event listener handling external calls for playing (used for safari permissions issue)
    window.addEventListener(
      'retorikSpiritEnginePlay',
      handleExternalCallForPlay
    )

    setTimeout(() => {
      moveNS.current &&
        moveNS.current.play().catch((e) => {
          e !== null && console.warn(e)
        })
      moveSP.current &&
        moveSP.current.play().catch((e) => {
          e !== null && console.warn(e)
        })
    }, 500)

    return () => {
      // Remove event listeners on component unmount
      window.removeEventListener('resize', detectSize)
      window.removeEventListener('preventChatbotSpeakAnimationsEvent', () =>
        setAllowSpeechAnimations(false)
      )
      window.removeEventListener('allowChatbotSpeakAnimationsEvent', () =>
        setAllowSpeechAnimations(true)
      )
      window.removeEventListener('setSpeaking', (e: CustomEvent) =>
        setSpeaking(e.detail)
      )
      window.removeEventListener(
        'retorikSpiritEnginePlay',
        handleExternalCallForPlay
      )
    }
  }, [])

  const handleEnd = () => {
    setMoving(!moving)
  }

  const handleAudioEnd = () => {
    document.dispatchEvent(new Event('retorikSpiritEngineAudioEnded'))
    setSpeaking(false)
  }

  const handleExternalCallForPlay = (): void => {
    if (moveNS.current && moveSP.current && stillNS.current) {
      // Check if a video (either still or move) is currently playing. If not -> start playing.
      if (
        !(
          moveNS.current.currentTime > 0 &&
          !moveNS.current.paused &&
          !moveNS.current.ended &&
          moveNS.current.readyState > 2
        ) &&
        !(
          stillNS.current.currentTime > 0 &&
          !stillNS.current.paused &&
          !stillNS.current.ended &&
          stillNS.current.readyState > 2
        )
      ) {
        moveNS.current.play().catch((e) => {
          e !== null && console.warn(e)
        })
        moveSP.current.play().catch((e) => {
          e !== null && console.warn(e)
        })
      }
    }
  }

  return (
    <div
      className='ses-relative ses-pointer-events-none ses-h-full ses-w-full ses-grid ses-justify-center ses-items-start ses-overflow-hidden'
      id='retorik-animation'
    >
      <audio
        id='spirit-engine-sprite-audio'
        onCanPlayThrough={() => setSpeaking(true)}
        onEnded={handleAudioEnd}
        onError={handleAudioEnd}
      />
      <canvas
        id='spirit-engine-sprite-canvas'
        className='ses-col-start-1 ses-row-start-1 ses-col-span-1 ses-row-span-1 ses-max-h-screen ses-self-start ses-justify-self-center ses-object-cover'
        ref={canvasRef}
        height={canvasHeight}
        width={canvasWidth}
        style={{
          marginTop: height || undefined
        }}
      />

      <Video
        ref={moveNS}
        onEnded={() => {}}
        srcMp4={`${agentData?.url}/mp4/${imageSize}/${idleAndExplainFolder}/ns/${currentMove}_ns.mp4`}
        srcWebm={`${agentData?.url}/webm/${imageSize}/${idleAndExplainFolder}/ns/${currentMove}_ns.webm`}
      />
      <Video
        ref={moveSP}
        onEnded={handleEnd}
        srcMp4={`${agentData?.url}/mp4/${imageSize}/${idleAndExplainFolder}/sp/${currentMove}_sp.mp4`}
        srcWebm={`${agentData?.url}/webm/${imageSize}/${idleAndExplainFolder}/sp/${currentMove}_sp.webm`}
      />
      <Video
        ref={stillNS}
        onEnded={() => {}}
        srcMp4={`${agentData?.url}/mp4/${imageSize}/${stillFolder}/ns/${currentStill}_ns.mp4`}
        srcWebm={`${agentData?.url}/webm/${imageSize}/${stillFolder}/ns/${currentStill}_ns.webm`}
      />
      <Video
        ref={stillSP}
        onEnded={handleEnd}
        srcMp4={`${agentData?.url}/mp4/${imageSize}/${stillFolder}/sp/${currentStill}_sp.mp4`}
        srcWebm={`${agentData?.url}/webm/${imageSize}/${stillFolder}/sp/${currentStill}_sp.webm`}
      />
    </div>
  )
}

export default Chatbot
