{
  "fr-FR": {
    "result": "résultat",
    "results": "résultats",
    "all": "tous",
    "filter": "Filtrer",
    "reset": "effacer tout",
    "showresults": "Afficher les résultats",
    "suggestiontitle": "Suggestions de recherches",
    "reminder": "N'oubliez pas,\n je peux répondre à vos questions."
  },
  "en-US": {
    "result": "result",
    "results": "results",
    "all": "all",
    "filter": "Filter",
    "reset": "reset filters",
    "showresults": "Show results",
    "suggestiontitle": "Research suggestions",
    "reminder": "Don't forget,\n I can answer your questions."
  },
  "es-ES": {
    "result": "resultado",
    "results": "resultados",
    "all": "todos",
    "filter": "Filtrar",
    "reset": "restablecer filtros",
    "showresults": "Mostrar resultados",
    "suggestiontitle": "Sugerencias de investigación",
    "reminder": "No olvide que puedo responder a sus preguntas."
  },
  "de-DE": {
    "result": "Ergebnis",
    "results": "Ergebnisse",
    "all": "alle",
    "filter": "Filter",
    "reset": "Filter zurücksetzen",
    "showresults": "Ergebnisse anzeigen",
    "suggestiontitle": "Forschungsvorschläge",
    "reminder": "Vergessen Sie nicht: Ich kann Ihre Fragen beantworten."
  }
}