import React, { useEffect, useState } from "react";
import type { WeatherData, FullWeatherData } from "../models/weatherTypes";
import { checkWeatherData } from "../utils/processWeatherData";
import { mobileDisplays } from "../models/constants";
import MobileWeather from "./WeatherContainers/MobileWeather";
import LargeWeather from "./WeatherContainers/LargeWeather";
import LargeVerticalWeather from "./WeatherContainers/LargeVerticalWeather";
import { DeviceType } from "../models/enums";

interface WeatherProps {
  locale?: string;
  position?: {
    latitude: number;
    longitude: number;
  };
  deviceType: number;
}

const Weather = ({
  locale,
  position,
  deviceType
}: WeatherProps): JSX.Element => {
  const [currentWeather, setCurrentWeather] = useState<WeatherData>();
  const [previsions, setPrevisions] = useState<Array<WeatherData>>([]);

  /**
   * On component mount :
   *  - check if there are weather data in the localstorage
   *  - if there are some, check if they were retrieved less than 1 hour ago
   *  - if so, set these data to the currentWeather and previsions states
   *  - otherwise, call getWeatherData
   */
  useEffect(() => {
    const savedWeatherData = localStorage.getItem("Retorik.Weather.Data");
    if (savedWeatherData) {
      const dataFromLocalStorage = JSON.parse(
        localStorage.getItem("Retorik.Weather.Data") || "null"
      );
      if (dataFromLocalStorage) {
        // Check if the data are more than 1-hour old, if so get new data
        const currentDateTime = Date.now();
        const oldDate = new Date(dataFromLocalStorage.current.time);
        if (currentDateTime - 3600000 > oldDate.getTime()) {
          getWeatherData();
        } else {
          setCurrentWeather(dataFromLocalStorage.current);
          setPrevisions(dataFromLocalStorage.prevision);
        }
      } else {
        getWeatherData();
      }
    } else {
      getWeatherData();
    }
  }, []);

  /**
   * On call :
   *  - try to get weather data from open-meteo API
   *  - if succeeded, fill currentWeather and previsions states with data
   */
  const getWeatherData = async (): Promise<void> => {
    if (position?.latitude && position?.longitude) {
      const fullWeatherData: FullWeatherData | null = await checkWeatherData(position);

      if (fullWeatherData) {
        setCurrentWeather(fullWeatherData.current);
        setPrevisions(fullWeatherData.prevision);
      }
    }
  };

  return currentWeather && previsions && previsions.length > 0 ? (
    mobileDisplays.includes(deviceType) ? (
      <MobileWeather
        locale={locale || "fr-FR"}
        currentWeather={currentWeather}
        previsions={previsions}
      />
    ) : deviceType !== DeviceType.borne ? (
      <LargeWeather
        locale={locale || "fr-FR"}
        currentWeather={currentWeather}
        previsions={previsions}
      />
    ) : (
      <LargeVerticalWeather
        locale={locale || "fr-FR"}
        currentWeather={currentWeather}
        previsions={previsions}
      />
    )
  ) : (
    <React.Fragment />
  );
};

export default Weather;
