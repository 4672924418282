{
  "fr-FR": {
    "warningtext": "Vous allez accéder à la chaîne info, le son de votre appareil va être activé",
    "warningcontinue": "Continuer",
    "warningback": "Annuler",
    "live": "direct",
    "continuous": "en continu"
  },
  "en-US": {
    "warningtext": "You are about to access the information channel, the sound of your device will be activated",
    "warningcontinue": "Continue",
    "warningback": "Abort",
    "live": "live",
    "continuous": "continuous"
  },
  "es-ES": {
    "warningtext": "Está a punto de acceder al canal de información, el sonido de su dispositivo se activará",
    "warningcontinue": "Continuar",
    "warningback": "Abortar",
    "live": "en directo",
    "continuous": "continuo"
  },
  "de-DE": {
    "warningtext": "Sie sind dabei, den Informationskanal aufzurufen, der Ton Ihres Geräts wird aktiviert",
    "warningcontinue": "Weiter",
    "warningback": "Abbrechen",
    "live": "live",
    "continuous": "fortlaufend"
  }
}
