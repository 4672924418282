/* eslint-disable react/jsx-boolean-value */
import React, { useMemo } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { setCurrentSubView } from '../Contexts/utilsStore'
import { CurrentSubView, Depth } from '../../models/enums'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { FullScreen } from '../Templates'
import translation from '../../translations/menu.json'
import { BackButton } from '../Menu/Common'
import AbsoluteLargeClosingButton from '../Utils/AbsoluteLargeClosingButton'

const CustomView = ({
  customView,
  title,
  icon,
  externalComponents,
  display,
  setDisplayCustomComponent
}): JSX.Element => {
  const { locale } = useLocaleStore()
  const { isMobile, displayControls } = useView()

  const handleBack = (): void => {
    setCurrentSubView(CurrentSubView.none)
    setDisplayCustomComponent(false)
  }
  const iconClassname =
    'rf-h-16 rf-w-16 large-vertical:rf-h-24 large-vertical:rf-w-24'
  const iconClassnameMobile = 'rf-h-8 rf-w-8'

  const componentToDisplay: JSX.Element = useMemo(() => {
    if (customView && customView.length > 0) {
      if (externalComponents) {
        const component = (
          externalComponents as Array<{ name: string; component: JSX.Element }>
        )?.find((e) => e.name === customView)
        if (component && component.component) {
          console.log(component.component)
          return component.component
        }
      }
    }
    return <></>
  }, [customView])

  return (
    <FullScreen
      className={`${!display && 'rf-hidden'} ${
        isMobile && displayControls ? 'rf-mb-12' : 'rf-mb-0'
      }`}
      depth={Depth.modal}
      background='#FFFFFF'
    >
      {/* title */}
      <div className='rf-absolute rf-z-util rf-w-full rf-top-0 rf-left-0'>
        {/* Mobile display */}
        <div className='large:rf-hidden rf-p-4 rf-flex rf-flex-col'>
          <BackButton
            title={translation[locale]?.back || translation['fr-FR'].back}
            className='rf-text-sm'
            handleBack={handleBack}
          />
          <div className='large:rf-hidden rf-py-2 rf-flex rf-flex-row rf-items-end undefined'>
            {icon ? <div className={iconClassnameMobile}>{icon} </div> : <></>}
            <div className='rf-ml-2 rf-capitalize rf-subtitle-size-auto'>
              {title ? capitalizeFirstLetter(title) : <></>}
            </div>
          </div>
        </div>

        <div className='rf-hidden large:rf-flex rf-w-full rf-pt-4 large-vertical:rf-pt-8 rf-pl-4 large-vertical:rf-pl-0 rf-flex-row rf-justify-start large-vertical:rf-justify-center rf-items-center'>
          {icon ? <div className={iconClassname}>{icon} </div> : <></>}
          <div className='rf-ml-2 rf-uppercase rf-subtitle-size-auto'>
            {title ? capitalizeFirstLetter(title) : <></>}
          </div>
        </div>
      </div>

      {componentToDisplay}

      {/* Closing button in large mode */}
      <AbsoluteLargeClosingButton
        dashboardVisible
        customComponent={true}
        onClick={handleBack}
      />
    </FullScreen>
  )
}

export default CustomView
