import React from 'react'
import { useView } from '../../Contexts/ViewContext'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useUtilsStore, setCurrentSubView } from '../../Contexts/utilsStore'
import translation from '../../../translations/menu.json'
import ParameterButton from './ParameterButton'
import { CurrentSubView } from '../../../models/enums'

interface HistoryButtonProps {
  handleBack?: () => void
}

const HistoryButton = ({ handleBack }: HistoryButtonProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { themeColors } = useView()
  const currentSubView = useUtilsStore((state) => state.currentSubView)

  /**
   * On call :
   *  - call parent's handleBack method if defined
   *  - change RetorikContext's currentSubView state
   */
  const handleClick = (): void => {
    handleBack && handleBack()
    setCurrentSubView(
      currentSubView === CurrentSubView.history
        ? CurrentSubView.none
        : CurrentSubView.history
    )
  }

  return (
    <ParameterButton
      title={translation[locale]?.history || translation['fr-FR'].history}
      icon='history'
      color={
        currentSubView === CurrentSubView.history
          ? themeColors.secondary
          : '#A4A7AF'
      }
      onClick={handleClick}
    />
  )
}

export default HistoryButton
