"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = onErrorResumeNext;

function onErrorResumeNext(fn) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  try {
    var result = fn.call(context);

    if (typeof result.then === 'function') {
      return new Promise(function (resolve) {
        result.then(resolve, function () {
          return resolve();
        });
      });
    } else {
      return result;
    }
  } catch (err) {}
}

module.exports = exports.default;
module.exports.default = exports.default;