{
  "fr-FR": {
    "button": "Besoin d'aide ?",
    "close": "Fermer"
  },
  "en-US": {
    "button": "Need help ?",
    "close": "Close"
  },
  "es-ES": {
    "button": "¿Necesita ayuda?",
    "close": "Cerrar"
  },
  "de-DE": {
    "button": "Brauchen Sie Hilfe?",
    "close": "Schließen"
  }
}
