// @ts-nocheck
import React, { useState, useEffect, useMemo } from 'react'
import {
  createDirectLine,
  createCognitiveServicesSpeechServicesPonyfillFactory
} from 'botframework-webchat'
import FullComposer from 'botframework-webchat/lib/FullComposer'
import type { AddressData, UserData } from '../../models/types'
import type { ThemeColors } from '../../models/colorTypes'
import { fetchDirectLineToken } from '../../utils/fetchDirectLineToken'
import createCustomStore from '../../utils/createCustomStore'
import SendActivityEventListener from '../Utils/SendActivityEventListener'
import type { Store } from 'redux'
import type { DirectLine } from 'botframework-directlinejs'
import { useSpeech } from '../Contexts/SpeechContext'
import { useRetorik } from '../Contexts/RetorikContext'
import type { WithChildren } from '../../models/utils'
import { useLocaleStore } from '../Contexts/localeStore'
import createAdaptiveCardHostConfig from '../../utils/createAdaptiveCardHostConfig'
import attachmentMiddleware from '../Utils/attachmentMiddleware'
import activityMiddleware from '../Utils/activityMiddleware'
import * as AC from 'adaptivecards/lib/adaptivecards'
import { useRetorikUI } from '../CustomAdaptiveCard'
import MarkdownIt from 'markdown-it'

// Add markdown-it to window for markdown processing
window.markdownit = MarkdownIt

useRetorikUI()

type RetorikComposerProps = WithChildren<{
  addressData: AddressData
  userData: UserData
  externalEventHandler?: (action: any, dispatch?: any) => boolean
  height?: number | 'full'
  width?: number | 'full'
  colors: ThemeColors
  isRetorikNews: boolean
}>

const defaultUser = {
  name: 'default',
  id: '',
  username: 'default',
  nom: 'defaultName',
  prenom: 'à vous',
  email: '',
  token: '',
  referrer: '',
  ipaddress: ''
}

/**
 * Component using DirectLine
 * @param props
 */
const RetorikComposer = ({
  addressData,
  userData,
  externalEventHandler,
  children,
  colors,
  isRetorikNews
}: RetorikComposerProps): JSX.Element => {
  const [directLine, setDirectLine] = useState<DirectLine | null>(null)
  const [store, setStore] = useState<Store | null>(null)
  const { ponyfillCredentials } = useSpeech()
  const { configuration, agentData, setAppAvailable, loaderClosed } =
    useRetorik()
  const { locale, supported } = useLocaleStore()
  const [hasConversationCookie, setHasConversationCookie] =
    useState<boolean>(false)

  useEffect(() => {
    const processDirectLine = async (): void => {
      fetchDirectLineToken(addressData).then((token) => {
        let test = true
        if (isRetorikNews) {
          const fullTenantName = (
            addressData.baseURI ||
            `${addressData.prefix || ''}${addressData.tenant}`
          ).replace(/;|,|\s/g, '')

          const newsConversationData = localStorage.getItem(
            `Retorik.News.Conversation.${fullTenantName}`
          )

          if (newsConversationData) {
            const data = JSON.parse(newsConversationData)
            if (data && data.conversationId) {
              setDirectLine(
                createDirectLine({
                  token: token,
                  conversationId: data.conversationId
                })
              )

              test = false
            }
          }
        } else if (configuration.enableConversationCookie !== false) {
          const conversationCookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('retorikConversationCookie='))
            ?.split('=')[1]

          if (conversationCookie) {
            const splitCookieData = conversationCookie.split('||')
            if (splitCookieData.length === 2) {
              const cookieConversationId = splitCookieData[0]
              const cookieTenantName = splitCookieData[1]
              const fullTenantName = (
                addressData.baseURI ||
                `${addressData.prefix || ''}${addressData.tenant}`
              ).replace(/;|,|\s/g, '')

              if (cookieTenantName === fullTenantName) {
                setDirectLine(
                  createDirectLine({
                    token: token,
                    conversationId: cookieConversationId,
                    watermark: '0'
                  })
                )

                setHasConversationCookie(true)
                test = false
              }
            }
          }
        }

        test &&
          setDirectLine(
            createDirectLine({
              token: token
            })
          )
      })
    }

    addressData && processDirectLine()
  }, [addressData])

  const ponyfillFactory = useMemo(() => {
    if (ponyfillCredentials) {
      const ponyfill = createCognitiveServicesSpeechServicesPonyfillFactory({
        credentials: ponyfillCredentials
      })
      return ponyfill
    }

    return null
  }, [ponyfillCredentials])

  // Initialize the store
  useEffect(() => {
    initStore()
  }, [])

  // Create custom store
  function initStore(): void {
    setStore(
      createCustomStore(
        configuration.enableConversationCookie,
        configuration.conversationCookieMaxAge,
        addressData,
        locale,
        externalEventHandler,
        !!configuration.isUsedOnBorne,
        isRetorikNews
      )
    )
  }

  const customAdaptiveCardHostConfig = createAdaptiveCardHostConfig(
    colors.primary,
    colors.card.frame.background
  )

  useEffect(() => {
    if (
      locale &&
      supported &&
      directLine &&
      ponyfillFactory &&
      store &&
      agentData &&
      (isRetorikNews || loaderClosed)
    ) {
      setAppAvailable(true)
    } else {
      setAppAvailable(false)
    }
  }, [
    locale,
    supported,
    directLine,
    ponyfillFactory,
    store,
    agentData,
    loaderClosed
  ])

  return directLine && ponyfillFactory && store && agentData ? (
    <FullComposer
      adaptiveCardsHostConfig={customAdaptiveCardHostConfig}
      adaptiveCardsPackage={AC}
      styleOptions={{
        primaryFont: '"Calibri", sans-serif',
        sendBoxTextWrap: true,
        sendBoxBackground: 'transparent'
      }}
      directLine={directLine}
      webSpeechPonyfillFactory={ponyfillFactory}
      store={store}
      locale={locale}
      attachmentMiddleware={attachmentMiddleware}
      activityMiddleware={activityMiddleware}
    >
      <SendActivityEventListener
        isRetorikNews={isRetorikNews}
        hasConversationCookie={hasConversationCookie}
        userData={userData || defaultUser}
      />
      {children}
    </FullComposer>
  ) : (
    <React.Fragment />
  )
}

export default RetorikComposer
