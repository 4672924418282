import React, { useEffect, useState } from 'react'
import {
  RetorikMainComponentConfiguration,
  RetorikMainComponentProps
} from '../../models/retorikTypes'
import RetorikContainer from './RetorikContainer'
import getRetorikConfigs from '../../utils/getConfigs'
import Loader from '../Loader/Loader'
import { ContainerParent } from '../../models/enums'
import getThemeColors from '../../utils/getThemeColors'

type RetorikConfigHandlerProps = RetorikMainComponentProps & {
  parent: number
}

const RetorikConfigHandler = (props: RetorikConfigHandlerProps) => {
  const [mainConfig, setMainConfig] = useState<
    RetorikMainComponentConfiguration | undefined
  >(undefined)
  const [isConfigUpdated, setIsConfigUpdated] = useState<boolean>(false)
  const [configurationFetchingEnded, setConfigurationFetchingEnded] =
    useState<boolean>(!!(isConfigUpdated || props.config !== undefined))

  // Handle configuration
  useEffect(() => {
    const getUpdatedConfig = async (): Promise<void> => {
      const fetchedConfig = await getRetorikConfigs(props)
      if (fetchedConfig && fetchedConfig.config) {
        const {
          config: {
            skipLoader = false,
            defaultMode,
            config: _config,
            viewsConfig,
            chatbotData,
            agentData,
            ponyfillFactoryCredentials,
            addressData,
            userData,
            customVoice,
            colors
          },
          externalConfigEnabled
        } = fetchedConfig
        console.log(externalConfigEnabled)

        const fetchedMainConfig: RetorikMainComponentConfiguration = {
          config: _config,
          viewsConfig: viewsConfig,
          chatbotData: chatbotData,
          agentData: agentData,
          ponyfillFactoryCredentials: ponyfillFactoryCredentials,
          addressData: addressData,
          userData: userData,
          customVoice: customVoice,
          colors: getThemeColors(colors),
          skipLoader: skipLoader,
          defaultMode: defaultMode,
          externalComponents: props.externalComponents,
          externalEventHandler: props.externalEventHandler
        }
        setMainConfig(fetchedMainConfig)
        setIsConfigUpdated(true)
        setConfigurationFetchingEnded(true)
      }
    }

    if (props.config !== undefined) {
      console.log('Get config from manualConfig')
      // Check mandatory properties
      if (
        props.viewsConfig &&
        props.chatbotData &&
        props.agentData &&
        props.ponyfillFactoryCredentials
      ) {
        setMainConfig(props)
        setIsConfigUpdated(true)
        setConfigurationFetchingEnded(true)
      } else {
        // Warnings for missing data in props
        console.warn('Mandatory configuration data missing : ')
        !props.viewsConfig && console.warn(' * viewsConfig')
        !props.chatbotData && console.warn(' * chatbotData')
        !props.agentData && console.warn(' * agentData')
        !props.ponyfillFactoryCredentials &&
          console.warn(' * ponyfillFactoryCredentials')
      }
    } else if (!isConfigUpdated) {
      console.log('Get config from Retorik')
      getUpdatedConfig()
    }
  }, [props])

  return mainConfig && configurationFetchingEnded ? (
    <RetorikContainer
      parent={props.parent}
      config={mainConfig}
      isConfigUpdated={isConfigUpdated}
    />
  ) : (
    <Loader
      className={isConfigUpdated ? 'rf-animate-loaderFadeOut' : ''}
      parent={props.parent}
      width={props.width}
      height={props.height}
      fullSize={
        !!(
          props.parent !== ContainerParent.widget &&
          mainConfig?.config?.fullSize !== false
        )
      }
    />
  )
}

export default RetorikConfigHandler
