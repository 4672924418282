import React from 'react'
import * as WeatherIcons from '../components/Icons'

/**
 * Cf https://www.jodc.go.jp/data_format/weather-code.html for weather codes and their corresponding weathers
 * @param code : number
 */

const getIconFromWeatherCode = (
  code: number,
  className: string
): JSX.Element => {
  if (code === 0) {
    return <WeatherIcons.SunIcon className={className} />
  } else if (code < 3) {
    return <WeatherIcons.SunCloudIcon className={className} />
  } else if (code === 3) {
    return <WeatherIcons.CloudIcon className={className} />
  } else if (code === 4) {
    return <WeatherIcons.SmokeIcon className={className} />
  } else if (code === 5) {
    return <WeatherIcons.FogIcon className={className} />
  } else if (code < 9) {
    return <WeatherIcons.DustIcon className={className} />
  } else if (code === 9) {
    return <WeatherIcons.SandStormIcon className={className} />
  } else if (code < 13) {
    return <WeatherIcons.FogIcon className={className} />
  } else if (code === 13) {
    return <WeatherIcons.ThunderIcon className={className} />
  } else if (code < 17) {
    return <WeatherIcons.LowRainIcon className={className} />
  } else if (code === 17) {
    return <WeatherIcons.ThunderStormIcon className={className} />
  } else if (code === 18) {
    return <WeatherIcons.HeavyWindIcon className={className} />
  } else if (code === 19) {
    return <WeatherIcons.TornadoIcon className={className} />
  } else if (code === 20) {
    return <WeatherIcons.DrizzleIcon className={className} />
  } else if (code === 21 || code === 23) {
    return <WeatherIcons.MediumRainIcon className={className} />
  } else if (code === 22 || code === 24) {
    return <WeatherIcons.MediumSnowIcon className={className} />
  } else if (code < 28) {
    return <WeatherIcons.HeavyRainIcon className={className} />
  } else if (code === 28) {
    return <WeatherIcons.FogIcon className={className} />
  } else if (code === 29) {
    return <WeatherIcons.ThunderStormIcon className={className} />
  } else if (code < 36) {
    return <WeatherIcons.SandStormIcon className={className} />
  } else if (code < 40) {
    return <WeatherIcons.HeavySnowIcon className={className} />
  } else if (code < 50) {
    return <WeatherIcons.FogIcon className={className} />
  } else if (code < 60) {
    return <WeatherIcons.DrizzleIcon className={className} />
  } else if (code < 62) {
    return <WeatherIcons.LowRainIcon className={className} />
  } else if (code < 64) {
    return <WeatherIcons.MediumRainIcon className={className} />
  } else if (code < 66) {
    return <WeatherIcons.HeavyRainIcon className={className} />
  } else if (code === 66 || code === 68) {
    return <WeatherIcons.LowRainIcon className={className} />
  } else if (code === 67 || code === 69) {
    return <WeatherIcons.MediumRainIcon className={className} />
  } else if (
    code === 70 ||
    code === 71 ||
    code === 76 ||
    code === 77 ||
    code === 78 ||
    code === 79
  ) {
    return <WeatherIcons.LowSnowIcon className={className} />
  } else if (code === 72 || code === 73) {
    return <WeatherIcons.MediumSnowIcon className={className} />
  } else if (code === 74 || code === 75) {
    return <WeatherIcons.HeavySnowIcon className={className} />
  } else if (code < 85) {
    return <WeatherIcons.HeavyRainIcon className={className} />
  } else if (code < 91) {
    return <WeatherIcons.HeavySnowIcon className={className} />
  } else if (code < 93) {
    return <WeatherIcons.MediumRainIcon className={className} />
  } else if (code < 95) {
    return <WeatherIcons.MediumSnowIcon className={className} />
  } else {
    return <WeatherIcons.ThunderStormShowerIcon className={className} />
  }
}

export { getIconFromWeatherCode }
