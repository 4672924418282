import React, { useState, useEffect } from 'react'
import { DashboardIcon } from '../../Icons/MenuIcons'
import translation from '../../../translations/menu.json'
import { useLocaleStore } from '../../Contexts/localeStore'
import { CloseIcon } from '../../Icons/Miscellaneous'

interface DashboardButtonProps {
  dashboardVisible: boolean
  onClick: () => void
}

const DashboardButton = ({
  dashboardVisible,
  onClick
}: DashboardButtonProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const [label, setLabel] = useState<string>('')

  useEffect(() => {
    setLabel(
      dashboardVisible
        ? translation[locale]?.close || translation['fr-FR'].close
        : translation[locale]?.menu || translation['fr-FR'].menu
    )
  }, [locale])

  return (
    <button
      className='rf-w-18 rf-h-18 rf-mb-6 rf-flex rf-flex-col rf-justify-center rf-items-center rf-bg-truewhite rf-text-xsm rf-text-trueblack rf-border rf-border-menuBorder rf-rounded-half hover:rf-cursor-pointer rf-shadow-[0_3px_6px_#606267]'
      aria-label={label}
      // data-map used in interactive map to use this button externaly
      data-map={dashboardVisible ? 'fermer' : 'dashboard'}
      onClick={onClick}
    >
      <div className='rf-uppercase'>{label}</div>
      {dashboardVisible ? (
        <CloseIcon className='rf-h-6 rf-w-6' />
      ) : (
        <DashboardIcon className='rf-h-6 rf-w-6' />
      )}
    </button>
  )
}

export default DashboardButton
