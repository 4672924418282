import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect
} from 'react'
import type { RetorikActivity } from '../../models/activityTypes'
import { Mode, RetorikEvent } from '../../models/enums'
import type {
  CustomVoice,
  PonyfillFactoryCredentials
} from '../../models/types'
import { useRetorik } from './RetorikContext'
import { setRetorikEvent, setWaitingForData } from './utilsStore'
import { Boundary } from '../../models/speechTypes'
import { useView } from './ViewContext'

export type SpeechProviderProps = {
  ponyfillFactoryCredentials: PonyfillFactoryCredentials
  customVoice: CustomVoice | undefined
  children?: ReactNode
}

type SpeechContextType = {
  voice: SpeechSynthesisVoice | null
  customVoice: CustomVoice | undefined
  speaking: boolean
  currentReplyToId: string | undefined
  currentPlaying: RetorikActivity | undefined
  endedActivities: Array<string>
  currentOrLastPlayedActivity: RetorikActivity | undefined
  ponyfillCredentials: PonyfillFactoryCredentials
  muted: boolean
  boundaryData: Array<Boundary>
  setVoice: (x: SpeechSynthesisVoice | null) => void
  setSpeaking: (x: boolean) => void
  setCurrentReplyToId: (x: string | undefined) => void
  setCurrentPlaying: (x: RetorikActivity | undefined) => void
  setEndedActivities: (x: Array<string>) => void
  setPonyfillCredentials: (x: PonyfillFactoryCredentials) => void
  setMuted: (x: boolean) => void
  setBoundaryData: (x: Array<Boundary>) => void
}

const SpeechContextDefaultValues: SpeechContextType = {
  voice: null,
  customVoice: undefined,
  speaking: false,
  currentReplyToId: undefined,
  currentPlaying: undefined,
  endedActivities: [],
  currentOrLastPlayedActivity: undefined,
  ponyfillCredentials: {
    region: 'francecentral',
    subscriptionKey: ''
  },
  muted: false,
  boundaryData: [],
  setVoice: () => {},
  setSpeaking: () => {},
  setCurrentReplyToId: () => {},
  setCurrentPlaying: () => {},
  setEndedActivities: () => {},
  setPonyfillCredentials: () => {},
  setMuted: () => {},
  setBoundaryData: () => {}
}

export const SpeechContext = createContext<SpeechContextType>(
  SpeechContextDefaultValues
)

export function useSpeech(): SpeechContextType {
  return useContext(SpeechContext)
}

export function SpeechProvider({
  ponyfillFactoryCredentials,
  customVoice,
  children
}: SpeechProviderProps): JSX.Element {
  const { mode } = useRetorik()
  const { showHomeAttachments, setHomeActivity } = useView()
  const [voice, setVoice] = useState<SpeechSynthesisVoice | null>(null)
  const [speaking, setSpeaking] = useState<boolean>(false)
  const [currentReplyToId, setCurrentReplyToId] = useState<string | undefined>(
    undefined
  )
  const [currentPlaying, setCurrentPlaying] = useState<
    RetorikActivity | undefined
  >(undefined)
  const [endedActivities, setEndedActivities] = useState<Array<string>>([])
  const [currentOrLastPlayedActivity, setCurrentOrLastPlayedActivity] =
    useState<RetorikActivity | undefined>(undefined)
  const [ponyfillCredentials, setPonyfillCredentials] =
    useState<PonyfillFactoryCredentials>(ponyfillFactoryCredentials)
  const [muted, setMuted] = useState<boolean>(false)
  const [boundaryData, setBoundaryData] = useState<Array<Boundary>>([])

  const value = useMemo(
    () => ({
      voice: voice,
      speaking: speaking,
      currentReplyToId: currentReplyToId,
      currentPlaying: currentPlaying,
      endedActivities: endedActivities,
      currentOrLastPlayedActivity: currentOrLastPlayedActivity,
      ponyfillCredentials: ponyfillCredentials,
      muted: muted,
      boundaryData: boundaryData,
      customVoice,
      setVoice,
      setSpeaking,
      setCurrentReplyToId,
      setCurrentPlaying,
      setEndedActivities,
      setPonyfillCredentials,
      setMuted,
      setBoundaryData
    }),
    [
      voice,
      speaking,
      currentReplyToId,
      currentPlaying,
      endedActivities,
      currentOrLastPlayedActivity,
      ponyfillCredentials,
      muted,
      boundaryData,
      customVoice,
      setVoice,
      setSpeaking,
      setCurrentReplyToId,
      setCurrentPlaying,
      setEndedActivities,
      setPonyfillCredentials,
      setMuted,
      setBoundaryData
    ]
  )

  useEffect(() => {
    if (currentPlaying) {
      currentPlaying.messageType === 'welcome' &&
        setHomeActivity(currentPlaying)
      setCurrentOrLastPlayedActivity(currentPlaying)
      setWaitingForData(false)
    }
  }, [currentPlaying])

  useEffect(() => {
    !showHomeAttachments && setCurrentOrLastPlayedActivity(undefined)
  }, [showHomeAttachments])

  useEffect(() => {
    setMuted(mode === Mode.text)
  }, [mode])

  useEffect(() => {
    setRetorikEvent(
      speaking ? RetorikEvent.SpeechStarted : RetorikEvent.SpeechEnded
    )
  }, [speaking])

  return (
    <React.Fragment>
      <SpeechContext.Provider value={value}>{children}</SpeechContext.Provider>
    </React.Fragment>
  )
}
