import React, { useState, useEffect } from 'react'
import { useView } from '../../Contexts/ViewContext'
import { useLocaleStore } from '../../Contexts/localeStore'
import {
  setCurrentCustomView,
  setCurrentSubView
} from '../../Contexts/utilsStore'
import { AvailableViews, CurrentSubView } from '../../../models/enums'
import translation from '../../../translations/views.json'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { mobileDisplays } from '../../../models/constants'
import DisplayIcon from './DisplayIcon'
import type { CustomMenu, LocalizedStrings } from '../../../models/types'

interface DisplayGeneratorProps {
  indice: number
  view: number
  device: number
  handleClick?: () => void
  conditionalHandleClick?: () => void
  type: string
}

interface CustomMenuDisplayGeneratorProps {
  indice: number
  label: LocalizedStrings | string
  device: number
  handleClick?: () => void
  conditionalHandleClick?: () => void
  icon: JSX.Element
  customDisplay?: string
  type: string
  name?: string
}

function instanceOfDisplayGeneratorProps(
  object: any
): object is DisplayGeneratorProps {
  return 'view' in object && object.type === 'BaseMenu'
}

function instanceOfCustomMenuDisplayGeneratorProps(
  object: any
): object is CustomMenuDisplayGeneratorProps {
  return 'icon' in object && object.type === 'CustomMenu'
}

const DisplayGenerator = (
  props: DisplayGeneratorProps | CustomMenuDisplayGeneratorProps
): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const {
    setRoute,
    isMobile,
    homeActivity,
    setShowHomeAttachments,
    themeColors
  } = useView()
  const [label, setLabel] = useState<string>('')
  const [colorButtonHandle, setColorButtonHandle] = useState<string>('')

  /**
   * On component mount :
   *  - set label state depending on the locale
   */
  useEffect(() => {
    if (instanceOfDisplayGeneratorProps(props)) {
      setLabel(
        translation[locale]?.[props.view.toString()] ||
          translation['fr-FR'][props.view.toString()]
      )
    } else if (instanceOfCustomMenuDisplayGeneratorProps(props)) {
      if (props.label && typeof props.label === 'string') {
        setLabel(props.label)
      } else if (props.label && typeof props.label === 'object') {
        setLabel(props.label[locale])
      }
    }
  }, [locale])

  useEffect(() => {
    let colorButtonHandle = ''
    // Handle hospitality menu icon color
    if (instanceOfCustomMenuDisplayGeneratorProps(props) && props.name) {
      if (props.name.toString().indexOf('whatToDo') > -1) {
        colorButtonHandle = themeColors.tobeDoneColor
      } else if (props.name.toString().indexOf('localProducts') > -1) {
        colorButtonHandle = themeColors.localProductsColor
      } else if (props.name.toString().indexOf('whereToEat') > -1) {
        colorButtonHandle = themeColors.whereToEatColor
      } else if (props.name.toString().indexOf('whereToSleep') > -1) {
        colorButtonHandle = themeColors.whereToSleepColor
      } else if (props.name.toString().indexOf('whatToSee') > -1) {
        colorButtonHandle = themeColors.tobeSeenColor
      } else if (props.name.toString().indexOf('agenda') > -1) {
        colorButtonHandle = themeColors.agendaColor
      } else if (props.name.toString().indexOf('services') > -1) {
        colorButtonHandle = themeColors.servicesColor
      }
    }
    setColorButtonHandle(colorButtonHandle)
  }, [label])

  /**
   * On call :
   *  - switch on chosen view
   *  - either display an overlaying page or redirect to another view
   *  - if handleClick is defined, call it
   */
  const handleClickOnCard = (): void => {
    if (instanceOfDisplayGeneratorProps(props)) {
      switch (props.view) {
        case AvailableViews.home:
          homeActivity && setShowHomeAttachments(homeActivity)
          isMobile &&
            props.conditionalHandleClick &&
            props.conditionalHandleClick()
          break
        case AvailableViews.news:
          setRoute('news')
          break
        case AvailableViews.weather:
          setCurrentSubView(CurrentSubView.weather)
          break
        case AvailableViews.emergency:
          setCurrentSubView(CurrentSubView.emergency)
          break
      }
    } else if (instanceOfCustomMenuDisplayGeneratorProps(props)) {
      // Call props' closing function in mobile mode if asked by the custom component
      isMobile && props.conditionalHandleClick && props.conditionalHandleClick()
      // Display custom component if present
      if (props.customDisplay) {
        setCurrentCustomView({ ...props } as CustomMenu)
      }
    }

    props.handleClick && props.handleClick()
  }

  return mobileDisplays.includes(props.device) ? (
    /* On mobile / widget : display available views as lines */
    <button
      className={`rf-w-full rf-py-3 rf-flex rf-flex-row rf-items-center ${
        props.indice !== 0 && 'rf-border-t rf-border-t-menuBorder'
      }`}
      onClick={handleClickOnCard}
      aria-label={label}
    >
      {instanceOfDisplayGeneratorProps(props) ? (
        <DisplayIcon view={props.view} />
      ) : (
        <DisplayIcon
          icon={props.icon}
          className='rf-max-h-6 rf-max-w-6 rf-flex rf-items-center'
          color={colorButtonHandle}
        />
      )}
      <div className='rf-ml-4 rf-font-bold'>{capitalizeFirstLetter(label)}</div>
    </button>
  ) : (
    /* On desktop / borne : display available views as buttons at the center of the screen */
    <button
      className='rf-h-36 rf-w-36 rf-p-4 rf-flex rf-flex-col rf-items-center rf-justify-between rf-rounded rf-shadow-[3px_3px_16px_#00000029]'
      style={{
        background: 'linear-gradient(to left top, #F2F3F6, #E5E6EC)'
      }}
      onClick={handleClickOnCard}
      aria-label={label}
    >
      {instanceOfDisplayGeneratorProps(props) ? (
        <DisplayIcon view={props.view} className='rf-h-16' />
      ) : (
        <DisplayIcon
          icon={props.icon}
          className='rf-h-16 rf-w-16 rf-flex'
          color={colorButtonHandle}
        />
      )}
      <div className='rf-uppercase rf-text-[#575F6B] rf-text-xs large-vertical:rf-text-basecustomuppercase'>
        {label}
      </div>
    </button>
  )
}

export default DisplayGenerator
