{
  "ar-EG": {
    "close": "اغلاق"
  },
  "ar-SA": {
    "close": "اغلاق"
  },
  "ca-ES": {
    "close": "Tanca"
  },
  "cs-CZ": {
    "close": "Zavřít"
  },
  "da-DK": {
    "close": "Tæt"
  },
  "de-AT": {
    "close": "Schließen"
  },
  "de-CH": {
    "close": "Schließen"
  },
  "de-DE": {
    "close": "Schließen"
  },
  "en-CA": {
    "close": "Close"
  },
  "en-GB": {
    "close": "Close"
  },
  "en-HK": {
    "close": "Close"
  },
  "en-IE": {
    "close": "Close"
  },
  "en-IN": {
    "close": "Close"
  },
  "en-US": {
    "close": "Close"
  },
  "es-ES": {
    "close": "Cerrar"
  },
  "es-MX": {
    "close": "Cerrar"
  },
  "fi-FI": {
    "close": "kiinni"
  },
  "fr-BE": {
    "close": "Fermer"
  },
  "fr-CA": {
    "close": "Fermer"
  },
  "fr-CH": {
    "close": "Fermer"
  },
  "fr-FR": {
    "close": "Fermer"
  },
  "hi-IN": {
    "close": "बंद करना"
  },
  "hu-HU": {
    "close": "Bezárás"
  },
  "id-ID": {
    "close": "Menutup"
  },
  "it-IT": {
    "close": "Chiudere"
  },
  "ja-JP": {
    "close": "近い"
  },
  "ko-KR": {
    "close": "닫다"
  },
  "nb-NO": {
    "close": "Lukk"
  },
  "nl-BE": {
    "close": "sluiten"
  },
  "nl-NL": {
    "close": "sluiten"
  },
  "pl-PL": {
    "close": "Zamknąć"
  },
  "pt-BR": {
    "close": "Fechar"
  },
  "pt-PT": {
    "close": "Fechar"
  },
  "ru-RU": {
    "close": "Закрывать"
  },
  "sv-SE": {
    "close": "Stänga"
  },
  "th-TH": {
    "close": "ปิด"
  },
  "tr-TR": {
    "close": "Kapalı"
  },
  "zh-CN": {
    "close": "关闭"
  },
  "zh-HK": {
    "close": "关闭"
  },
  "zh-TW": {
    "close": "关闭"
  }
}
