import React from 'react'
import type { RetorikMainComponentProps } from '../models/retorikTypes'
import ShadowRoot from './Common/ShadowRoot'
import { ContainerParent } from '../models/enums'
import RetorikConfigHandler from './Common/RetorikConfigHandler'

const RetorikAgent = (props: RetorikMainComponentProps): JSX.Element => {
  // --------- /!\ -----------
  // Ne mettre ici que de la logique spécifique au retorikAgent
  // Ce qui est commun avec RetorikWidget ou autres doit aller dans RetorikComposer
  // -------------------------

  return (
    <ShadowRoot>
      <RetorikConfigHandler {...{ parent: ContainerParent.agent, ...props }} />
    </ShadowRoot>
  )
}

export default RetorikAgent
