{
  "fr-FR": {
    "menu": "menu",
    "mode": "mode",
    "parameters": "paramètres",
    "sound": "son",
    "subtitle": "sous-titre",
    "tutorial": "tutoriel",
    "history": "historique",
    "lang": "langues",
    "singleLang": "langue",
    "message": "Saisissez votre message",
    "allowMicrophone": "Autorisez votre navigateur à utiliser votre micro pour continuer",
    "back": "retour",
    "backconversation": "retour à la conversation",
    "close": "fermer",
    "answer": " répond à vos questions"
  },
  "en-US": {
    "menu": "menu",
    "mode": "mode",
    "parameters": "parameters",
    "sound": "sound",
    "subtitle": "subtitle",
    "tutorial": "tutorial",
    "history": "history",
    "lang": "languages",
    "singleLang": "language",
    "message": "Enter your message",
    "allowMicrophone": "Give your browser permission to use microphone to continue",
    "back": "back",
    "backconversation": "back to the conversation",
    "close": "close",
    "answer": " answers your questions"
  },
  "es-ES": {
    "menu": "menú",
    "mode": "modo",
    "parameters": "parámetros",
    "sound": "sonido",
    "subtitle": "subtítulos",
    "tutorial": "tutorial",
    "history": "historia",
    "lang": "idiomas",
    "singleLang": "idioma",
    "message": "Escriba su mensaje",
    "allowMicrophone": "Dé permiso a su navegador para utilizar el micrófono para continuar",
    "back": "volver",
    "backconversation": "volver a la conversación",
    "close": "cerrar",
    "answer": " responde a tus preguntas"
  },
  "de-DE": {
    "menu": "Menü",
    "mode": "Modus",
    "parameters": "Parameter",
    "sound": "Ton",
    "subtitle": "Untertitel",
    "tutorial": "Lernprogramm",
    "history": "Verlauf",
    "lang": "Sprachen",
    "singleLang": "Sprache",
    "message": "Geben Sie Ihre Nachricht ein",
    "allowMicrophone": "Geben Sie Ihrem Browser die Erlaubnis, das Mikrofon zu benutzen, um fortzufahren",
    "back": "zurück",
    "backconversation": "zurück zum Gespräch",
    "close": "schließen",
    "answer": " beantwortet Ihre Fragen"
  }
}
