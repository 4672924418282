import React, { useEffect, useRef, useState } from 'react'
import type { Ponyfill, Boundary } from '../../models/speechTypes'
import type { SpeechSynthesisUtterance } from '@davi-ai/web-speech-cognitive-services-davi/lib/SpeechServices'
import { useRetorik } from '../Contexts/RetorikContext'
import { useSpeech } from '../Contexts/SpeechContext'
import LoaderCallToAction from '../Loader/LoaderCallToAction'
import { audioFiles } from '../../utils/audioFiles'
import IndexedDbManager from './IndexedDbManager'
import { useLocaleStore } from '../Contexts/localeStore'

interface RetorikSpeechProps {
  ponyfill?: Ponyfill
  onEnd?: () => void
  onError?: () => void
  onStart?: () => void
  utterance?: SpeechSynthesisUtterance
  appAvailable: boolean
}

const DBManager = new IndexedDbManager()
const tagsRemoverRegex = /<[^<>]+>/g

const RetorikSpeech = ({
  ponyfill,
  onEnd,
  onError,
  onStart,
  utterance,
  appAvailable
}: RetorikSpeechProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const {
    loaderClosed,
    setLoaderClosed,
    configuration: { enableSpeechCaching }
  } = useRetorik()
  const { setBoundaryData, muted, voice } = useSpeech()
  const boundaryRef = useRef<Array<Boundary> | undefined | null>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [DBReady, setDBReady] = useState<boolean>(false)
  const [playingBlob, setPlayingBlob] = useState<boolean>(false)

  const emptyTextUtteranceRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    DBManager.checkDB().then((result) => setDBReady(result))
  }, [])

  const stopCurrentPlaying = (): void => {
    if (appAvailable || loaderClosed) {
      // Cancel synthesis if using synthesizer
      if (!playingBlob) {
        ponyfill && ponyfill.speechSynthesis.cancel()
      }

      if (audioRef.current && !audioRef.current?.paused) {
        audioRef.current.pause()
        handleEnd()
      }
    }
  }

  useEffect(() => {
    if (utterance) {
      if (utterance.text) {
        if (ponyfill && audioRef?.current) {
          utterance.onerror = (event): void => {
            handleError(event)
          }

          utterance.onsynthesiscompleted = (): void => {
            const endBoundary: Boundary = {
              word: '',
              startTime: 0,
              endTime: 0,
              boundaryType: 'EndBoundary'
            }

            const tempBoundaries = boundaryRef?.current || []
            boundaryRef.current = [...tempBoundaries, endBoundary]
            setBoundaryData([...tempBoundaries, endBoundary])
          }

          utterance.onboundary = (event): void => {
            if (event.boundaryType !== 'Viseme') {
              // Get the current word and its start time and end time.
              const word = event.name
              const startTime = event.elapsedTime
              const endTime = event.elapsedTime + event.duration
              const boundaryType = event.boundaryType
              // Update the boundaryData state with the new data.
              const tempBoundaries = boundaryRef?.current || []
              boundaryRef.current = [
                ...tempBoundaries,
                { word, startTime, endTime, boundaryType }
              ]
              setBoundaryData([
                ...tempBoundaries,
                { word, startTime, endTime, boundaryType }
              ])
            }
          }

          // Play utterance whether by asking for a speech synthesis, or by retrieving data fro indexeddb
          playUtterance()
        } else {
          stopCurrentPlaying()
        }
      } else {
        handleEmptyTextUtterance()
      }
    } else {
      stopCurrentPlaying()
    }

    return (): void => {
      stopCurrentPlaying()
      emptyTextUtteranceRef?.current &&
        clearTimeout(emptyTextUtteranceRef.current)
    }
  }, [utterance])

  const playUtterance = async (): Promise<void> => {
    if (DBReady && enableSpeechCaching !== false) {
      const dataFromIndexedDB = await DBManager.getSpeechData(
        `${locale}.${voice?.name}.${utterance.text.replace(
          tagsRemoverRegex,
          ''
        )}`
      )

      if (dataFromIndexedDB) {
        boundaryRef.current = dataFromIndexedDB.boundaries
        setBoundaryData(dataFromIndexedDB.boundaries)
        processData(dataFromIndexedDB.value, true)
      } else {
        ponyfill?.speechSynthesis.synthesizeAndGetArrayData(
          utterance,
          processData
        )
      }
    } else {
      ponyfill?.speechSynthesis.synthesizeAndGetArrayData(
        utterance,
        processData
      )
    }
  }

  const processData = (data: ArrayBuffer, alreadyExistsInDB?: boolean) => {
    const blob = new Blob([data], { type: 'audio/mp3' })

    enableSpeechCaching !== false &&
      !alreadyExistsInDB &&
      DBReady &&
      boundaryRef?.current &&
      DBManager.addSpeechData({
        id: `${locale}.${voice?.name}.${utterance.text.replace(
          tagsRemoverRegex,
          ''
        )}`,
        value: data,
        boundaries: boundaryRef.current
      })

    const url = URL.createObjectURL(blob)

    if (audioRef?.current) {
      setPlayingBlob(true)
      audioRef.current.src = url
      audioRef.current.play()
    }
  }

  const resetData = (): void => {
    boundaryRef.current = []
    setBoundaryData([])
    setPlayingBlob(false)
  }

  const handleStart = (): void => {
    if (appAvailable || loaderClosed) {
      onStart && onStart()
    }
  }

  const handleEnd = (): void => {
    if (appAvailable || loaderClosed) {
      onEnd && onEnd()
      resetData()
    } else {
      setLoaderClosed(true)
    }
  }

  const handleError = (error): void => {
    console.log('Error : ', error)
    onError && onError()
    resetData()
  }

  const handleEmptyTextUtterance = (): void => {
    handleStart()
    emptyTextUtteranceRef?.current &&
      clearTimeout(emptyTextUtteranceRef.current)
    emptyTextUtteranceRef.current = setTimeout(() => {
      handleEnd()
    }, 50)
  }

  /**
   * On call :
   *  - play 1 second muted sound to prime audio output
   *  - MANDATORY FOR SAFARI IN VOCAL MODE
   */
  const primeRetorikSpeech = (): void => {
    if (ponyfill && audioRef.current) {
      audioRef.current.play().catch((e) => console.warn(e))

      // Send animation start event to secure animation not playing on safari if permissions are not sufficient
      window.dispatchEvent(new Event('retorikSpiritEnginePlay'))
    }
  }

  return (
    <React.Fragment>
      <audio
        ref={audioRef}
        src={audioFiles.onesecond}
        muted={appAvailable || loaderClosed ? muted : true}
        onPlay={handleStart}
        onEnded={handleEnd}
      />
      {!(appAvailable || loaderClosed) && (
        <LoaderCallToAction handleValidation={primeRetorikSpeech} />
      )}
    </React.Fragment>
  )
}

RetorikSpeech.defaultProps = {
  ponyfill: undefined,
  onEnd: undefined,
  onError: undefined,
  onStart: undefined,
  utterance: undefined,
  appAvailable: false
}

export default RetorikSpeech
