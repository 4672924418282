import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

interface Props {
  svgUrl: string // L'URL de l'image SVG
  fillColor: string // La couleur finale souhaitée
  height: string // La hauteur de l'image
}

const SvgColorChanger: React.FC<Props> = ({ svgUrl, fillColor, height }) => {
  const [modifiedSvg, setModifiedSvg] = useState<string | null>(null)

  useEffect(() => {
    // Fonction pour charger et modifier le contenu SVG
    const loadAndModifySvg = async () => {
      try {
        // Récupérez le contenu SVG via Fetch
        const response = await fetch(svgUrl)
        const svgContent = await response.text()
        // Modifiez le contenu SVG pour changer la couleur et la taille
        const modifiedSvgContent = svgContent
          .replace(/fill="#[A-Fa-f0-9]+"/g, `fill="${fillColor}"`)
          .replace(/fill='#[A-Fa-f0-9]+'/g, `fill='${fillColor}'`)
          .replace(/<svg([^>]*)>/, `<svg$1 height="${height}">`)

        // Mettez à jour l'état avec le SVG modifié
        setModifiedSvg(modifiedSvgContent)
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération du SVG :",
          error
        )
      }
    }

    // Chargez et modifiez le SVG lorsque le composant est monté
    loadAndModifySvg()
  }, [svgUrl, fillColor])

  return <>{modifiedSvg && parse(modifiedSvg)}</>
}

export default SvgColorChanger
