import { hooks } from 'botframework-webchat'
import type { HistoryActivity, RetorikActivity } from '../models/activityTypes'
import { createNewQuestionForTimestamp } from '../utils/activityUtils'

const { useActivities } = hooks

export default function useActivitiesTextMode(): Array<HistoryActivity> {
  const [allActivities] = useActivities()
  // processedId contains the ids of the activities already processed (because useActivities gives duplicate activities with different state depending on if it's been spoken or not )
  const processedId: Array<string> = []
  const history: HistoryActivity[] = []
  const activities = allActivities
    .slice()
    .filter(({ type }) => type === 'message')

  activities.forEach((activity) => {
    if (activity.id && !processedId.includes(activity.id)) {
      processedId.push(activity.id)
      // Check if this is a question or an answer (questions from the user don't have a replyToId field)
      if (activity.replyToId) {
        const question: HistoryActivity | undefined = history.find(
          (historyItem) => historyItem.question.id === activity.replyToId
        )
        if (question) {
          question.answers.push(activity)
        } else {
          const newQuestion: RetorikActivity =
            createNewQuestionForTimestamp(activity)

          history.push({
            question: newQuestion,
            answers: [activity]
          })
        }
      } else {
        // If the activity has a value, it is a need for further informations that don't need to be displayed
        if (!(activity as RetorikActivity).value) {
          // Hack for Bringup Message to be displayed as bot message
          if (
            (activity as RetorikActivity).label === 'Davi.BringupMessage' &&
            (activity as RetorikActivity).text
          ) {
            const newQuestion: RetorikActivity =
              createNewQuestionForTimestamp(activity)
            history.push({
              question: newQuestion,
              answers: [activity]
            })
          } else {
            history.push({
              question: activity,
              answers: []
            })
          }
        }
      }
    }
  })

  return history
}
