{
  "fr-FR": {
    "firstline": "Pour une expérience optimale,",
    "secondline": "basculez votre écran"
  },
  "en-US": {
    "firstline": "For an optimal experience,",
    "secondline": "flip your screen"
  },
  "es-ES": {
    "firstline": "Para una experiencia óptima",
    "secondline": "voltea la pantalla"
  },
  "de-DE": {
    "firstline": "Für ein optimales Erlebnis,",
    "secondline": "drehen Sie Ihren Bildschirm um"
  }
}
