/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useView } from '../../Contexts/ViewContext'
import { useRetorik } from '../../Contexts/RetorikContext'
import ShowPhoneNumber from './ShowPhoneNumber'
import CommentsPennant from './CommentsPennant'
import Map from './Map'
import type {
  AddressStructured,
  Coordinates
} from '../../../models/attachmentTypes'
import { DeviceType } from '../../../models/enums'
import DownloadSheet from './DownloadSheet'
import DownloadQR from './DownloadQR'

type DetailedAddressProps = {
  address?: AddressStructured
  phoneNumber?: string
  coordinates?: Coordinates
  color?: string
  colorWithTransparency?: string
  customerReviews?: number
  url?: string
}

const DetailedAddress = ({
  address,
  phoneNumber,
  coordinates,
  color,
  colorWithTransparency,
  customerReviews,
  url
}: DetailedAddressProps): JSX.Element => {
  const { currentWidth, currentDeviceType, isMobile } = useView()
  const { configuration } = useRetorik()

  return (
    <div className='rf-relative rf-w-1/3 vertical:rf-w-full large:rf-bg-truewhite rf-flex rf-flex-col large-vertical:rf-flex-row'>
      {/* Pennant with comments */}
      {customerReviews && (
        <CommentsPennant
          className='rf-absolute large-vertical:rf-hidden rf-top-0 rf-left-1/2 rf--translate-x-1/2'
          color={color}
          customerReviews={customerReviews}
        />
      )}

      {/* Address + phone number */}
      <div
        className={`rf-px-6 rf-flex rf-flex-col large-vertical:rf-w-1/2 rf-items-center rf-text-center ${
          customerReviews
            ? currentDeviceType !== DeviceType.borne
              ? 'rf-bg-truewhite rf-justify-start'
              : 'rf-bg-truewhite rf-justify-center'
            : 'rf-justify-center'
        }`}
        style={{
          height: isMobile
            ? customerReviews
              ? currentWidth
              : undefined
            : currentDeviceType === DeviceType.landscape
            ? coordinates && coordinates.latitude && coordinates.longitude
              ? '50%'
              : '100%'
            : 'calc(50vw - 3rem)'
        }}
      >
        <div
          className={
            customerReviews && currentDeviceType !== DeviceType.borne
              ? currentDeviceType === DeviceType.landscape
                ? 'rf-pt-[26vh] rf-pb-12'
                : 'rf-pt-11/20 rf-pb-12'
              : 'rf-py-12'
          }
        >
          {address && (
            <React.Fragment>
              {address.street && <div>{address.street}</div>}
              {address.addressLocality && (
                <div>
                  {address.postalCode
                    ? `${address.postalCode} ${address.addressLocality}`
                    : address.addressLocality}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {phoneNumber && <ShowPhoneNumber phoneNumber={phoneNumber} />}

        {/* QRcode to download data in borne mode */}
        {coordinates &&
          coordinates.latitude &&
          coordinates.longitude &&
          currentDeviceType === DeviceType.borne &&
          url &&
          (configuration.isUsedOnBorne ? (
            <DownloadQR url={url} />
          ) : (
            <DownloadSheet className='rf-justify-center' url={url} />
          ))}
      </div>

      {/* Map if coordinates are given / download button in borne mode if no coordinate */}
      {coordinates && coordinates.latitude && coordinates.longitude ? (
        <div
          className='rf-relative large-vertical:rf-w-1/2'
          style={{
            height: isMobile
              ? currentWidth
              : currentDeviceType === DeviceType.landscape
              ? '50%'
              : 'calc(50vw - 3rem)'
          }}
        >
          <Map
            latitude={coordinates.latitude}
            longitude={coordinates.longitude}
            color={color}
            colorWithTransparency={colorWithTransparency}
          />
        </div>
      ) : currentDeviceType === DeviceType.borne ? (
        <div className='rf-flex rf-justify-center rf-items-center rf-h-[calc(50vw-3rem)] rf-w-1/2'>
          {url &&
            (configuration.isUsedOnBorne ? (
              <DownloadQR url={url} />
            ) : (
              <DownloadSheet className='rf-justify-center' url={url} />
            ))}
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  )
}

export default DetailedAddress
