import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect
} from 'react'
import type { AddressData, AgentData, Configuration } from '../../models/types'
import { Mode } from '../../models/enums'
import { setLastListActivity } from './utilsStore'

export type RetorikProviderProps = {
  chosenMode: number
  config: Configuration
  currentLocale: string
  allLocales?: string[]
  agentManifest: AgentData
  skipLoader?: boolean
  children?: ReactNode
  addressData: AddressData
}

type LocationData = {
  searchForLocation: boolean
  latitude: number
  longitude: number
  city?: string
  country?: string
}

export type RetorikContextType = {
  mode: number
  configuration: Configuration
  displaySubtitles: boolean
  allLanguages: string[]
  agentData: AgentData | undefined
  appAvailable: boolean
  loaderClosed: boolean
  addressData: AddressData
  canFocusSendBox: boolean
  setMode: (x: number) => void
  setConfiguration: (x: Configuration) => void
  setDisplaySubtitles: (x: boolean) => void
  setAllLanguages: (x: string[]) => void
  setAgentData: (x: AgentData) => void
  setAppAvailable: (x: boolean) => void
  setLoaderClosed: (x: boolean) => void
  setCurrentAddressData: (x: AddressData) => void
  setSendBoxRef: (x: HTMLTextAreaElement) => void
  focusSendBox: () => void
  setCanFocusSendBox: (x: boolean) => void
}

const defaultConfiguration: Configuration = {
  subtitles: false
}

const RetorikContextDefaultValues: RetorikContextType = {
  mode: Mode.vocal,
  configuration: defaultConfiguration,
  displaySubtitles: false,
  allLanguages: [],
  agentData: undefined,
  appAvailable: false,
  loaderClosed: false,
  addressData: {},
  canFocusSendBox: false,
  setMode: () => {},
  setConfiguration: () => {},
  setDisplaySubtitles: () => {},
  setAllLanguages: () => {},
  setAgentData: () => {},
  setAppAvailable: () => {},
  setLoaderClosed: () => {},
  setCurrentAddressData: () => {},
  setSendBoxRef: () => {},
  focusSendBox: () => {},
  setCanFocusSendBox: () => {}
}

export const RetorikContext = createContext<RetorikContextType>(
  RetorikContextDefaultValues
)

export function useRetorik(): RetorikContextType {
  return useContext(RetorikContext)
}

export function RetorikProvider({
  skipLoader,
  chosenMode,
  config,
  currentLocale,
  allLocales,
  agentManifest,
  children,
  addressData
}: RetorikProviderProps): JSX.Element {
  const [currentAddressData, setCurrentAddressData] =
    useState<AddressData>(addressData)
  const [mode, setMode] = useState<number>(chosenMode)
  const [configuration, setConfiguration] = useState<Configuration>(config)
  const [displaySubtitles, setDisplaySubtitles] = useState<boolean>(
    config.subtitles || mode === Mode.text
  )
  const [locale, setLocale] = useState<string>(currentLocale)
  const [allLanguages, setAllLanguages] = useState<string[]>(allLocales || [])
  const [agentData, setAgentData] = useState<AgentData>(agentManifest)
  const [appAvailable, setAppAvailable] = useState<boolean>(false)
  const [loaderClosed, setLoaderClosed] = useState<boolean>(!!skipLoader)
  const [sendBoxRef, setSendBoxRef] = useState<HTMLTextAreaElement | null>(null)
  const [canFocusSendBox, setCanFocusSendBox] = useState<boolean>(false)
  const focusSendBox = (): void => {
    sendBoxRef && document.activeElement !== sendBoxRef && sendBoxRef.focus()
  }

  const value = useMemo(
    () => ({
      mode: mode,
      configuration: configuration,
      displaySubtitles: displaySubtitles,
      locale: locale,
      allLanguages: allLanguages,
      agentData: agentData,
      appAvailable: appAvailable,
      loaderClosed: loaderClosed,
      currentAddressData: currentAddressData,
      addressData: addressData,
      canFocusSendBox: canFocusSendBox,
      setConfiguration,
      setDisplaySubtitles,
      setLocale,
      setAllLanguages,
      setAgentData,
      setAppAvailable,
      setMode,
      setLoaderClosed,
      setCurrentAddressData,
      setSendBoxRef,
      focusSendBox,
      setCanFocusSendBox
    }),
    [
      mode,
      configuration,
      displaySubtitles,
      locale,
      allLanguages,
      agentData,
      appAvailable,
      loaderClosed,
      currentAddressData,
      canFocusSendBox,
      setConfiguration,
      setDisplaySubtitles,
      setLocale,
      setAllLanguages,
      setAgentData,
      setAppAvailable,
      setMode,
      setLoaderClosed,
      setCurrentAddressData,
      setSendBoxRef,
      setCanFocusSendBox
    ]
  )

  const setLocation = async (data): Promise<void> => {
    const position: LocationData = {
      searchForLocation: true,
      latitude: data.coords.latitude,
      longitude: data.coords.longitude
    }

    localStorage.setItem('Retorik.Framework.Location', JSON.stringify(position))
    /*
    const cityName = await getCity(data.coords.latitude, data.coords.longitude)
    if (cityName) {
      position.city = cityName.city
      position.country = cityName.country
    }
    */
    setConfiguration({
      ...configuration,
      position: position
    })
  }

  // TODO find a stronger solution for this (using free api service with limited usage)
  /*
  const getCity = async (
    latitude,
    longitude
  ): Promise<{ city: string; country: string } | undefined> => {
    console.log('using reverse geocoding to fetch city')
    return fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=3ee1620fde1f4bbeb44b2d01314ae5da`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.results?.length) {
          const result = data.results[0].components
          if (result) {
            return { city: result.city, country: result.country }
          }
        }
        return undefined
      })
      .catch((error) => {
        console.log(error)
        return undefined
      })
  }
  */

  // get position from navigator
  useEffect(() => {
    if (configuration.position?.searchForLocation) {
      // Position enabled
      if (
        configuration.position?.latitude === undefined &&
        configuration.position?.longitude === undefined
      ) {
        // Try to get the position of the device if no one has been given in the configuration
        const geoloc = navigator.geolocation
        geoloc.getCurrentPosition(
          setLocation,
          () => {
            console.log('navigator.geolocalisation failed')
          },
          {
            timeout: 3000
          }
        )
      }
    }
  }, [])

  /**
   * On chosenMode props change :
   *  - update mode state
   *  - update displaySubtitles state if no data has been provided in the configuration
   */
  useEffect(() => {
    setMode(chosenMode)
  }, [chosenMode])

  /**
   * On mode state change :
   *  - update displaySubtitles state depending on the mode
   */
  useEffect(() => {
    if (!config.subtitles) {
      setDisplaySubtitles(false)
    }
    setLastListActivity(undefined)
  }, [mode])

  /**
   * On config props change :
   *  - update configuration state
   */
  useEffect(() => {
    setConfiguration(config)
  }, [config])

  /**
   * On configuration.position change :
   *  - set the new data in localStorage
   */
  useEffect(() => {
    localStorage.setItem(
      'Retorik.Framework.Location',
      JSON.stringify(configuration.position || null)
    )
  }, [configuration.position])

  /**
   * On locale change :
   *  - dispatch CustomEvent 'retorikChangeLocale' with locale in detail field for external use
   */
  useEffect(() => {
    const changeLocaleEvent = new CustomEvent('retorikChangeLocale', {
      detail: {
        locale: locale
      }
    })
    document.dispatchEvent(changeLocaleEvent)
  }, [locale])

  /**
   * On agentManifest change :
   *  - Set Agent Data if different
   */
  useEffect(() => {
    if (agentData !== agentManifest) {
      console.log('Switch Agent Data', agentManifest)
      setAgentData(agentManifest)
    }
  }, [agentManifest])

  useEffect(() => {
    setLoaderClosed(!!skipLoader)
  }, [skipLoader])

  return (
    <RetorikContext.Provider value={value}>{children}</RetorikContext.Provider>
  )
}
