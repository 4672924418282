{
  "fr-FR": {
    "title": "une erreur est survenue",
    "button": "Relancer l'application"
  },
  "en-US": {
    "title": "An error occurred",
    "button": "Launch the application again"
  },
  "es-ES": {
    "title": "Se ha producido un error",
    "button": "Vuelva a iniciar la aplicación"
  },
  "de-DE": {
    "title": "Es ist ein Fehler aufgetreten",
    "button": "Starten Sie die Anwendung erneut"
  }
}
