import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import translation from '../../translations/error.json'

const ErrorFallback = ({ resetErrorBoundary }): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const handleReset = () => {
    resetErrorBoundary()
    window.location.reload()
  }

  return (
    <div className='rf-h-full rf-w-full rf-flex rf-flex-col rf-justify-center rf-items-center rf-gap-2'>
      <p className='rf-uppercase'>
        {translation[locale]?.title || translation['fr-FR'].title}
      </p>
      <button onClick={handleReset} className='rf-border rf-px-4'>
        {translation[locale]?.button || translation['fr-FR'].button}
      </button>
    </div>
  )
}

export default ErrorFallback
