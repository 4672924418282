import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect
} from 'react'
import type { ThemeColors } from '../../models/colorTypes'
import type { ViewsConfiguration } from '../../models/types'
import { defaultThemeDark as defaultColors } from '../../models/colors'
import { DeviceType } from '../../models/enums'
import { mobileDisplays, mediumBreakpoint } from '../../models/constants'
import { WebChatActivity } from 'botframework-webchat-core'
import { RetorikActivity } from '../../models/activityTypes'

export type ViewsProviderProps = {
  viewsConfiguration: ViewsConfiguration
  themeColors: ThemeColors
  children?: ReactNode
}

export type ViewContextType = {
  configurations: ViewsConfiguration
  route: string
  currentDeviceType: number
  currentHeight: number
  currentWidth: number
  themeColors: ThemeColors
  isTactile: boolean
  isMobile: boolean
  isMobileLandscape: boolean
  displayControls: boolean
  homeActivity: RetorikActivity | undefined
  showHomeAttachments: WebChatActivity | undefined
  mediumLandscape: boolean
  setConfigurations: (x: ViewsConfiguration) => void
  setRoute: (x: string) => void
  setCurrentDeviceType: (x: number) => void
  setCurrentHeight: (x: number) => void
  setCurrentWidth: (x: number) => void
  setDisplayControls: (x: boolean) => void
  setHomeActivity: (x: RetorikActivity | undefined) => void
  setShowHomeAttachments: (x: WebChatActivity | undefined) => void
}

const defaultConfiguration: ViewsConfiguration = {
  homeRoute: 'home',
  views: {
    home: {
      background: {
        image: '',
        style: 'image'
      }
    }
  }
}

const ViewContextDefaultValues: ViewContextType = {
  configurations: defaultConfiguration,
  route: 'home',
  currentDeviceType: DeviceType.mobile,
  currentHeight: 1080,
  currentWidth: 1920,
  themeColors: defaultColors,
  isTactile: false,
  isMobile: false,
  isMobileLandscape: false,
  displayControls: true,
  homeActivity: undefined,
  showHomeAttachments: undefined,
  mediumLandscape: false,
  setConfigurations: () => {},
  setRoute: () => {},
  setCurrentDeviceType: () => {},
  setCurrentHeight: () => {},
  setCurrentWidth: () => {},
  setDisplayControls: () => {},
  setHomeActivity: () => {},
  setShowHomeAttachments: () => {}
}

export const ViewContext = createContext<ViewContextType>(
  ViewContextDefaultValues
)

export function useView(): ViewContextType {
  return useContext(ViewContext)
}

export function ViewProvider({
  viewsConfiguration,
  themeColors,
  children
}: ViewsProviderProps): JSX.Element {
  const [configurations, setConfigurations] =
    useState<ViewsConfiguration>(viewsConfiguration)
  const [route, setRoute] = useState<string>(configurations.homeRoute || 'home')
  const [currentDeviceType, setCurrentDeviceType] = useState<number>(
    DeviceType.mobile
  )
  const [currentHeight, setCurrentHeight] = useState<number>(0)
  const [currentWidth, setCurrentWidth] = useState<number>(0)
  const mediumLandscape = useMemo<boolean>(() => {
    return currentWidth < mediumBreakpoint
  }, [currentWidth])
  const [isTactile, setIsTactile] = useState<boolean>(false)
  const isMobile = useMemo<boolean>(() => {
    return mobileDisplays.includes(currentDeviceType)
  }, [currentDeviceType])
  const isMobileLandscape = useMemo<boolean>(() => {
    if (
      currentDeviceType === DeviceType.mobile &&
      currentHeight < currentWidth
    ) {
      return true
    }

    return false
  }, [currentHeight, currentWidth, currentDeviceType])
  const [displayControls, setDisplayControls] = useState<boolean>(true)
  const [homeActivity, setHomeActivity] = useState<RetorikActivity | undefined>(
    undefined
  )
  const [showHomeAttachments, setShowHomeAttachments] = useState<
    WebChatActivity | undefined
  >(undefined)

  const value = useMemo(
    () => ({
      configurations: configurations,
      route: route,
      currentDeviceType: currentDeviceType,
      currentHeight: currentHeight,
      currentWidth: currentWidth,
      themeColors: themeColors,
      isTactile: isTactile,
      isMobile: isMobile,
      isMobileLandscape: isMobileLandscape,
      displayControls: displayControls,
      homeActivity: homeActivity,
      showHomeAttachments: showHomeAttachments,
      mediumLandscape: mediumLandscape,
      setConfigurations,
      setRoute,
      setCurrentDeviceType,
      setCurrentHeight,
      setCurrentWidth,
      setDisplayControls,
      setShowHomeAttachments,
      setHomeActivity
    }),
    [
      configurations,
      route,
      currentDeviceType,
      currentHeight,
      currentWidth,
      themeColors,
      isTactile,
      isMobileLandscape,
      displayControls,
      homeActivity,
      showHomeAttachments,
      mediumLandscape,
      setConfigurations,
      setRoute,
      setCurrentDeviceType,
      setCurrentHeight,
      setCurrentWidth,
      setDisplayControls,
      setShowHomeAttachments,
      setHomeActivity
    ]
  )

  useEffect(() => {
    setIsTactile('ontouchstart' in window || navigator.maxTouchPoints > 0)
  }, [])

  useEffect(() => {
    setConfigurations(viewsConfiguration)
  }, [viewsConfiguration])

  return (
    <React.Fragment>
      <ViewContext.Provider value={value}>{children}</ViewContext.Provider>
    </React.Fragment>
  )
}
