import React, { useEffect, useRef } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useView } from '../../Contexts/ViewContext'
import translation from '../../../translations/menu.json'
import CommentsPennant from './CommentsPennant'
import { FavoriteIcon } from '../../Icons/DetailedPOIIcons'
import { BackButton } from '../../Menu/Common'

type DetailedTitleProps = {
  category?: {
    id: string
    label: string
  }
  title?: string
  infoBanner?: string
  color?: string
  customerReviews?: number
  favorite?: boolean
  handleClose: () => void
  setTitleHeight: (x: number) => void
}

const DetailedTitle = ({
  category,
  title,
  infoBanner,
  color,
  customerReviews,
  favorite,
  handleClose,
  setTitleHeight
}: DetailedTitleProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { isMobile } = useView()
  const titleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    titleRef?.current && setTitleHeight(titleRef.current.clientHeight)
  }, [titleRef?.current])

  return (
    <div
      ref={titleRef}
      className='rf-relative rf-pb-4 rf-flex-col rf-items-start rf-justify-center'
    >
      {/* Category + comments' pennant */}
      {customerReviews && (
        <CommentsPennant
          className='rf-absolute rf-hidden large-vertical:rf-block rf-top-0 rf-right-0'
          color={color}
          customerReviews={customerReviews}
        />
      )}
      <div className='rf-w-full rf-flex rf-flex-row rf-justify-between rf-items-start'>
        {isMobile && (
          <BackButton
            title={translation[locale]?.back || translation['fr-FR'].back}
            handleBack={handleClose}
            className='rf-relative rf-mt-4 rf-text-size-auto'
          />
        )}
        <div
          className='rf-uppercase rf-w-fit rf-py-2 large:rf-py-3 rf-px-4 rf-bg-black rf-text-primary'
          style={{ color: color }}
        >
          {category?.label || 'category'}
        </div>
      </div>

      {/* 'Favorite' icon + title */}
      <div className='rf-py-6 rf-title-large-bold-size-auto large-vertical:rf-pt-[16.67%] rf-flex rf-flex-row rf-items-center rf-font-bold'>
        {favorite && <FavoriteIcon className='rf-h-16 rf-w-20 rf-pr-4' />}
        {title || ''}
      </div>

      {/* Informations Banner */}
      {infoBanner && (
        <div
          className='rf-py-1 rf-px-4 rf-subtitle-size-auto rf-bg-primary rf-text-truewhite'
          style={{ backgroundColor: color }}
        >
          {infoBanner}
        </div>
      )}
    </div>
  )
}

export default DetailedTitle
