/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react'
import RetorikComposer from './RetorikComposer'
import RetorikRouter from './RetorikRouter'
import Container from './Container'
import ContextProvider from '../Contexts/ContextProvider'
import getThemeColors from '../../utils/getThemeColors'
import type { RetorikMainComponentConfiguration } from '../../models/retorikTypes'
import type { ThemeColors } from '../../models/colorTypes'
import NewsView from '../Views/NewsView'
import SpeechManager from '../Speech/SpeechManager'
import MobileLandscapePreventer from '../Utils/MobileLandscapePreventer'
import ErrorCollector from '../Telemetry/ErrorCollector'
import { ContainerParent, Mode } from '../../models/enums'

type RetorikContainerProps = {
  config: RetorikMainComponentConfiguration
  parent: number
  isConfigUpdated: boolean
}

const RetorikContainer = ({
  parent,
  isConfigUpdated,
  config: props
}: RetorikContainerProps): JSX.Element => {
  const [processedThemeColors] = useState<ThemeColors>(
    getThemeColors(props.colors)
  )

  return props.config &&
    props.viewsConfig &&
    props.chatbotData &&
    props.agentData &&
    props.ponyfillFactoryCredentials ? (
    <ContextProvider
      skipLoader={props.skipLoader}
      mode={props.defaultMode || Mode.vocal}
      viewsConfiguration={props.viewsConfig}
      config={props.config}
      addressData={props.addressData}
      agentSource={props.agentData}
      ponyfillFactoryCredentials={props.ponyfillFactoryCredentials}
      themeColors={processedThemeColors}
      isConfigUpdated={isConfigUpdated}
      customVoice={props.customVoice}
    >
      {!props.config.forceMobileView && <MobileLandscapePreventer />}
      <RetorikComposer
        addressData={props.addressData}
        userData={props.userData}
        externalEventHandler={props.externalEventHandler}
        colors={processedThemeColors}
        isRetorikNews={parent === ContainerParent.news}
      >
        <ErrorCollector>
          <SpeechManager isRetorikNews={parent === ContainerParent.news} />
          <Container
            fullSize={
              parent !== ContainerParent.widget &&
              props.config.fullSize !== false
            }
            width={props.width}
            height={props.height}
            colors={processedThemeColors}
            parent={parent}
          >
            {parent === ContainerParent.news ? (
              <NewsView
                chatbotData={props.chatbotData}
                hideMenu={true}
                isRetorikNews={true}
              />
            ) : (
              <RetorikRouter
                chatbotData={props.chatbotData}
                externalComponents={props.externalComponents}
              />
            )}
          </Container>
        </ErrorCollector>
      </RetorikComposer>
    </ContextProvider>
  ) : (
    <React.Fragment />
  )
}

export default RetorikContainer
