import { Boundary } from '../../models/speechTypes'

interface DBData {
  id: string
  value: ArrayBuffer
  boundaries: Array<Boundary>
}

class IndexedDbManager {
  version = 1
  storeName = 'speechSynthesisData'

  checkDB = async (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const request: IDBOpenDBRequest = indexedDB.open(
        'retorik-framework-db',
        this.version
      )
      request.onsuccess = () => {
        resolve(true)
      }

      request.onerror = () => {
        resolve(false)
      }

      request.onupgradeneeded = () => {
        const db = request.result

        // If the data object store doesn't exist, create it
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName, {
            keyPath: 'id'
          })
        }
      }
    })
  }

  addSpeechData = async (data: DBData): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const request: IDBOpenDBRequest = indexedDB.open(
        'retorik-framework-db',
        this.version
      )

      request.onsuccess = () => {
        const db = request.result
        const tx = db.transaction(this.storeName, 'readwrite')
        const store = tx.objectStore(this.storeName)
        store.add(data)
        resolve(true)
      }

      request.onerror = () => {
        resolve(false)
      }
    })
  }

  getSpeechData = async (id: string): Promise<DBData | null> => {
    return new Promise<DBData | null>((resolve) => {
      const request: IDBOpenDBRequest = indexedDB.open(
        'retorik-framework-db',
        this.version
      )

      request.onsuccess = () => {
        const db = request.result
        const tx = db.transaction(this.storeName, 'readonly')
        const store = tx.objectStore(this.storeName)
        const data = store.get(id)
        data.onsuccess = () => {
          resolve(data.result)
        }
      }

      request.onerror = () => {
        resolve(null)
      }
    })
  }
}

export default IndexedDbManager
