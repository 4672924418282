import { DeviceType } from "./enums";

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
};

const mobileDisplays = [DeviceType.mobile, DeviceType.widget];

const openMeteoBefore = "https://api.open-meteo.com/v1/forecast?";
const openMeteoAfter =
  "&daily=weathercode,temperature_2m_max,temperature_2m_min&timezone=auto&current_weather=true";

export { dateOptions, mobileDisplays, openMeteoBefore, openMeteoAfter };
