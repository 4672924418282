/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import BorderlessButtonMessage from './Utils/BorderlessButtonMessage'
import { useLocaleStore } from '../Contexts/localeStore'
import translation from '../../translations/suggestion.json'
import { InfoWithCircleIcon } from '../Icons/Miscellaneous'
import { useView } from '../Contexts/ViewContext'

interface SuggestionAttachmentProps {
  suggestions:
    | Array<string>
    | Array<{
        text: string
        differentTextTosend?: string
      }>
  title?: string
  description?: string
  height?: number
  history?: boolean
  showTutorial: boolean
}

const SuggestionAttachment = ({
  suggestions,
  title,
  description,
  height,
  history,
  showTutorial
}: SuggestionAttachmentProps): JSX.Element => {
  const { configuration } = useRetorik()
  const { locale } = useLocaleStore()
  const { themeColors } = useView()

  const onTutorialClick = (): void => {
    // TODO
    console.log('show tutorial')
  }

  return (
    <div
      className={`rf-w-full large:rf-p-[25px] rf-p-4 rf-flex rf-flex-col rf-items-center rf-gap-2 ${
        title || description ? 'rf-justify-between' : 'rf-justify-around'
      } rf-min-h-inherit rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-overflow-y-scroll rf-scrollbar-thin ${
        history && 'rf-pointer-events-none'
      } `}
      style={{
        height: height,
        touchAction: 'pan-y'
      }}
    >
      {/* Title if present */}
      {title && (
        // Title on the left if there is a description, middle otherwise
        <div
          className={`rf-w-full ${
            description ? 'rf-text-left' : 'rf-text-center'
          } rf-title-size-auto ${
            configuration.isUsedOnBorne ? 'rf-truncate' : 'rf-line-clamp-2'
          }`}
        >
          {capitalizeFirstLetter(title)}
        </div>
      )}

      {/* Description if present */}
      {description && (
        <div className='rf-text-left rf-w-full'>{description}</div>
      )}

      {/* Links */}
      {suggestions?.map((data, key) => {
        const text = typeof data === 'string' ? data : data.text
        const differentTextTosend =
          typeof data === 'string'
            ? undefined
            : data.differentTextToSend || data.text

        return (
          <React.Fragment key={key}>
            <BorderlessButtonMessage
              text={text}
              differentTextTosend={differentTextTosend}
            />
          </React.Fragment>
        )
      })}
      {showTutorial && (
        <div className='rf-text-left rf-w-full rf-flex rf-items-end rf-italic'>
          <InfoWithCircleIcon
            className='rf-pr-2 rf-h-6'
            color={themeColors.card.button.text.default}
          />
          {translation[locale]?.needhelp || translation['fr-FR'].needhelp}{' '}
          {translation[locale]?.consult || translation['fr-FR'].consult}
          <span
            className='rf-pl-1 rf-underline rf-cursor-pointer'
            onClick={onTutorialClick}
          >
            {translation[locale]?.thetutorial ||
              translation['fr-FR'].thetutorial}
          </span>
        </div>
      )}
    </div>
  )
}

export default SuggestionAttachment
