import React from 'react'
import { hooks } from 'botframework-webchat-component'
import type { DiscoverContentType } from '../../models/attachmentTypes'
import { useLocaleStore } from '../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { useView } from '../Contexts/ViewContext'
import SvgColorChanger from './Utils/SvgColorChanger'

const { useSendPostBack } = hooks

const DiscoverAttachment = ({
  title,
  mainButtons,
  recommandationButton,
  history,
  height
}: DiscoverContentType): JSX.Element => {
  const sendPostBack = useSendPostBack()
  const { locale } = useLocaleStore()
  const { isMobile, themeColors } = useView()
  const handleClick = (event: string): void => {
    // tap && sendPostBack(tap)
    sendPostBack({ name: event, type: 'event' })
  }

  return (
    <div
      className={`rf-w-full rf-flex rf-flex-col rf-items-center rf-justify-between rf-rounded rf-bg-cardFrameBackground large:rf-p-[25px] rf-p-4 ${
        history && 'rf-pointer-events-none'
      } `}
      style={{
        height: height
      }}
    >
      <div className='rf-min-h-inherit rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-self-start rf-title-size-auto rf-mb-2'>
        {capitalizeFirstLetter(title, locale)}
      </div>

      <div className='rf-w-full rf-h-full rf-flex rf-flex-row rf-justify-evenly rf-items-center rf-gap-2 large:rf-gap-4'>
        {mainButtons && mainButtons.length > 0 ? (
          mainButtons.map((button, i) => {
            let colorButtonHandle = ''
            if (button.EventName.indexOf('TouristAttractions') > -1) {
              colorButtonHandle = themeColors.tobeDoneColor
            } else if (button.EventName.indexOf('Products') > -1) {
              colorButtonHandle = themeColors.localProductsColor
            } else if (button.EventName.indexOf('FoodEstablishments') > -1) {
              colorButtonHandle = themeColors.whereToEatColor
            } else if (button.EventName.indexOf('LodgingBusinesses') > -1) {
              colorButtonHandle = themeColors.whereToSleepColor
            } else if (button.EventName.indexOf('TouristDestinations') > -1) {
              colorButtonHandle = themeColors.tobeSeenColor
            } else if (button.EventName.indexOf('Event') > -1) {
              colorButtonHandle = themeColors.agendaColor
            }
            return (
              <div
                key={i}
                className='rf-relative rf-card-button-discover rf-rounded rf-flex rf-flex-col rf-gap-2 large:rf-gap-4 rf-items-center'
                onClick={(): any => handleClick(button.EventName)}
                style={{
                  flex: isMobile ? '0 1 6rem' : '0 1 8rem',
                  boxShadow: '0px 0px 13px #0000009c'
                }}
              >
                {button.MediaUrl.indexOf('svg') > -1 &&
                colorButtonHandle !== '' ? (
                  <SvgColorChanger
                    svgUrl={button.MediaUrl}
                    height='50px'
                    fillColor={colorButtonHandle}
                  />
                ) : (
                  <img
                    src={button.MediaUrl}
                    alt={button.Text}
                    style={{ height: '50px' }}
                  />
                )}
                <p className='rf-text-center rf-uppercase rf-font-bold large:rf-text-base rf-text-xsl rf-whitespace-nowrap'>
                  {button.Text}
                </p>
              </div>
            )
          })
        ) : (
          <></>
        )}
      </div>

      {/* Subtitle 2 lines max / title 2 lines max */}
      {recommandationButton && (
        <p
          className='large:rf-mt-4 rf-font-bold rf-card-button rf-text-center rf-capitalize-first'
          onClick={(): any => handleClick(recommandationButton.eventName)}
        >
          {recommandationButton.text}
        </p>
      )}
    </div>
  )
}

export default DiscoverAttachment
