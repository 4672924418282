/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { useUtilsStore } from '../Contexts/utilsStore'

import type { ChatbotData } from '../../models/types'

import Background from '../Common/Background'
import UserRequest from '../AnswerPanel/UserRequest'
import ChatbotAndSubtitlesContainer from '../ChatbotAndSubtitles/ChatbotAndSubtitlesContainer'
import Logo from '../Common/Logo'
import BotResponse from '../AnswerPanel/BotResponse'
import RetorikLogo from '../Common/RetorikLogo'
import SubViews from './SubViews'
import { Mode } from '../../models/enums'
import { ConversationPanel } from '../AnswerPanel/ConversationPanel'
import MenuDisplayer from '../Menu/MenuDisplayer'

interface HomeViewProps {
  chatbotData: ChatbotData
  externalComponents?: Array<{ name: string; component: JSX.Element }>
}

const HomeView = ({
  chatbotData,
  externalComponents
}: HomeViewProps): JSX.Element => {
  const { configuration, mode, loaderClosed } = useRetorik()
  const { configurations, isMobile, setDisplayControls } = useView()
  const displayAgent = useUtilsStore((state) => state.displayAgent)
  const displayData = useMemo(() => {
    return configurations.views.home
  }, [configurations])

  useEffect(() => {
    setDisplayControls(!configuration.hideControls)
  }, [])

  return (
    <React.Fragment>
      {/* Background */}
      <Background config={displayData.background} />

      {/* Logo */}
      {configuration?.logo &&
        (!isMobile || (isMobile && mode !== Mode.text)) && <Logo />}

      {/* Chatbot + Subtitles */}
      {(!isMobile || (isMobile && mode !== Mode.text)) &&
        chatbotData &&
        displayAgent && (
          <ChatbotAndSubtitlesContainer
            className='rf-relative rf-col-start-1 rf-col-end-9 large:rf-col-end-6 large-vertical:rf-col-end-9 rf-row-span-full rf-self-end rf-h-full'
            chatbotData={chatbotData}
            showSubtitles={true}
          />
        )}

      {/* Activity input / output */}
      {configuration?.answerpanel !== false && loaderClosed && (
        <React.Fragment>
          <UserRequest />
          {mode === Mode.vocal ? <BotResponse /> : <ConversationPanel />}
        </React.Fragment>
      )}

      {/* Menus */}
      <MenuDisplayer hideMenu={configuration?.hideMenu} />

      {/* Miscellaneous */}
      {loaderClosed && <SubViews externalComponents={externalComponents} />}

      {/* Retorik Logo on right bottom */}
      {!configuration?.hideRetorikLogo && <RetorikLogo />}
    </React.Fragment>
  )
}

export default HomeView
