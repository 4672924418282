import { useEffect, useState } from 'react'
import { hooks } from 'botframework-webchat'
import { useRetorik } from '../Contexts/RetorikContext'
import {
  useUtilsStore,
  sendActivity as sendUtilActivity
} from '../Contexts/utilsStore'
import { useLocaleStore } from '../Contexts/localeStore'
import { UserData } from '../../models/types'

const { useMicrophoneButtonClick, usePostActivity, useSendEvent } = hooks

interface SendActivityEventListenerProps {
  isRetorikNews: boolean
  hasConversationCookie: boolean
  userData: UserData
}

const SendActivityEventListener = ({
  isRetorikNews,
  hasConversationCookie,
  userData
}: SendActivityEventListenerProps): null => {
  const sendPostActivity = usePostActivity()
  const sendEvent = useSendEvent()
  const locale = useLocaleStore((state) => state.locale)
  const activityToSend = useUtilsStore((state) => state.activityToSend)
  const microphoneButtonClick = useMicrophoneButtonClick()
  const {
    setDisplaySubtitles,
    appAvailable,
    loaderClosed,
    configuration: { skipWelcome }
  } = useRetorik()
  const [userSet, setUserSet] = useState<boolean>(
    isRetorikNews || hasConversationCookie
  )

  const sendActivity = (event: CustomEvent): void => {
    if (event.type === 'sendMessageEvent') {
      console.log(
        'sendMessageEvent has been replaced by retorikSendActivity. Please use retorikSendActivity instead'
      )
    } else if (event.detail) {
      sendPostActivity(event.detail)
    }
  }

  const handleStartListening = (): void => {
    microphoneButtonClick()
  }

  const handleDisplaySubtitles = (event: CustomEvent): void => {
    setDisplaySubtitles(!!event.detail)
  }

  useEffect(() => {
    document.addEventListener('sendMessageEvent', sendActivity)
    document.addEventListener('retorikSendActivity', sendActivity)
    document.addEventListener('retorikStartListening', handleStartListening)
    document.addEventListener('retorikDisplaySubtitles', handleDisplaySubtitles)

    return (): void => {
      document.removeEventListener('sendMessageEvent', sendActivity)
      document.removeEventListener('retorikSendActivity', sendActivity)
      document.removeEventListener(
        'retorikStartListening',
        handleStartListening
      )
      document.removeEventListener(
        'retorikDisplaySubtitles',
        handleDisplaySubtitles
      )
    }
  }, [])

  useEffect(() => {
    if (!userSet && (appAvailable || loaderClosed)) {
      setUserSet(true)
      setTimeout(() => {
        sendEvent('setUser', {
          user: userData,
          skipWelcome: !!skipWelcome
        })
      }, 1000)
    }
  }, [appAvailable, loaderClosed])

  useEffect(() => {
    if (activityToSend) {
      if (
        activityToSend.type === 'message' &&
        activityToSend.text &&
        typeof activityToSend.text !== 'string'
      ) {
        const tempActivity = { ...activityToSend }
        tempActivity.text = activityToSend.text[locale] || ''
        sendPostActivity(tempActivity)
      } else {
        sendPostActivity(activityToSend)
      }
      sendUtilActivity(undefined)
    }
  }, [activityToSend])

  return null
}

export default SendActivityEventListener
