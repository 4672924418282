import React from 'react'
import { isMobile } from 'react-device-detect'
import Animation from './Animation'
import { ContainerParent } from '../../models/enums'

interface LoaderProps {
  className: string
  parent: ContainerParent
  width?: number | string
  height?: number | string
  fullSize: boolean
}

const Loader = ({
  className,
  parent,
  width,
  height,
  fullSize
}: LoaderProps): JSX.Element => {
  const animationColor = {
    '--rf-color-loader-animation': '#00D7FF'
  } as React.CSSProperties

  let widthStyle = {}
  let heightStyle = {}

  if (parent === ContainerParent.widget) {
    widthStyle = { width: '100%' }
    heightStyle = { height: height }
  }

  if (width === 'full') {
    widthStyle = { width: '100%' }
  } else if (typeof width === 'number') {
    widthStyle = { width: `${width}px` }
  } else if (typeof width === 'string') {
    widthStyle = { width: width }
  }

  if (height === 'full') {
    heightStyle = { height: '100%', flexGrow: 1 }
  } else if (typeof height === 'number') {
    heightStyle = { height: `${height}px` }
  } else if (typeof height === 'string') {
    heightStyle = { height: height }
  }

  if (isMobile) {
    heightStyle = {
      height: window.innerHeight,
      minHeight: window.innerHeight,
      maxHeight: window.innerHeight
    }
  }

  return (
    <div
      className={`${
        fullSize ? 'rf-w-screen rf-h-screen' : 'rf-w-full rf-h-full'
      } rf-max-w-screen rf-max-h-screen rf-bg-white ${className}`}
      style={{
        ...widthStyle,
        ...heightStyle
      }}
    >
      <div
        className='rf-h-full rf-w-full rf-flex rf-flex-col rf-items-center'
        style={{
          background: 'rgba(18,18,18,0.7)',
          ...animationColor
        }}
      >
        {/* Animation + Text */}
        <div className='rf-w-full rf-h-1/2 rf-pb-6 rf-flex rf-flex-col rf-items-center rf-justify-end'>
          {/* Animation */}
          <div className='rf-w-6 rf-h-6 rf-mb-16'>
            <Animation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
