var _Object$setPrototypeOf = require("core-js-pure/features/object/set-prototype-of.js");
var _bindInstanceProperty = require("core-js-pure/features/instance/bind.js");
function _setPrototypeOf(o, p) {
  var _context;
  module.exports = _setPrototypeOf = _Object$setPrototypeOf ? _bindInstanceProperty(_context = _Object$setPrototypeOf).call(_context) : function _setPrototypeOf(o, p) {
    o.__proto__ = p;
    return o;
  }, module.exports.__esModule = true, module.exports["default"] = module.exports;
  return _setPrototypeOf(o, p);
}
module.exports = _setPrototypeOf, module.exports.__esModule = true, module.exports["default"] = module.exports;