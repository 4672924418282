import React, { useCallback, useEffect, useState } from 'react'
import { hooks } from 'botframework-webchat'
import { useView } from '../../Contexts/ViewContext'
import {
  setFullScreenImage,
  setCurrentSubView
} from '../../Contexts/utilsStore'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/menu.json'
import animation from '../../../assets/lottie/recording.json'
import getMicrophonePermission from '../../../utils/getMicrophonePermission'
import { Player } from '@lottiefiles/react-lottie-player'
import Modal from '../../Common/Modal'
import * as Icons from '../../Icons/MenuIcons'
import { CurrentSubView } from '../../../models/enums'

const { useMicrophoneButtonClick, useSendBoxSpeechInterimsVisible } = hooks

interface MicrophoneButtonProps {
  openMicrophone?: boolean
}

const MicrophoneButton = ({
  openMicrophone
}: MicrophoneButtonProps): JSX.Element => {
  const { isMobile, themeColors } = useView()
  const { locale } = useLocaleStore()
  const microphoneClick = useMicrophoneButtonClick()
  const [interimsVisible] = useSendBoxSpeechInterimsVisible()
  const [allowed, setAllowed] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [playerRef, setPlayerRef] = useState<any>(null)

  const handleClick = useCallback((): void => {
    if (!allowed) {
      askPermission()
    } else {
      startListening()
    }

    document.dispatchEvent(new Event('cancelSpeech'))
  }, [allowed, microphoneClick])

  useEffect(() => {
    openMicrophone && handleClick()
  }, [])

  /**
   * On interimsVisible change :
   *  - if the interims are visible (waiting for user's speak input), play the lottie animation
   *  - if the interims are not visible (user's speak ended), stop the lottie animation
   *  - remove subviews if visible
   *  - remove fullscreen image if there is one
   */
  useEffect(() => {
    if (interimsVisible) {
      playerRef && playerRef.lottie.play()
    } else {
      playerRef && playerRef.lottie.stop()
    }

    setCurrentSubView(CurrentSubView.none)
    setFullScreenImage(undefined)
  }, [interimsVisible, playerRef])

  useEffect(() => {
    if (allowed) {
      closeModal()
      startListening()
    }
  }, [allowed])

  const startListening = (): void => {
    microphoneClick()
  }

  const closeModal = (): void => {
    setMessage('')
  }

  const askPermission = (): Promise<void> => {
    setMessage(
      translation[locale]?.allowMicrophone ||
        'Give your browser permission to use microphone to continue'
    )
    return getMicrophonePermission()
      .then((permission) => {
        setAllowed(permission)
      })
      .catch(console.log)
  }

  return (
    <React.Fragment>
      <button
        className={`rf-relative large:rf-w-full large:rf-h-auto rf-aspect-square rf-flex rf-justify-center rf-items-center rf-border ${
          interimsVisible
            ? isMobile
              ? 'rf-h-12 rf-border-[#00000029]'
              : 'rf-border-[#00000029]'
            : isMobile
            ? 'rf-h-8 rf-border-none'
            : 'rf-border-menuBorder'
        } rf-rounded-half rf-overflow-hidden`}
        onClick={handleClick}
        aria-label='microphone'
        style={{
          boxShadow: interimsVisible
            ? 'inset 0 0 6px 3px#00000029'
            : isMobile
            ? undefined
            : '0 0 20px 1px #A4A7AF80',
          WebkitMaskImage: interimsVisible
            ? '-webkit-radial-gradient(white, black)'
            : undefined
        }}
      >
        <div className='rf-absolute rf-h-full rf-w-full rf-flex rf-justify-end'>
          {interimsVisible && (
            <Player
              lottieRef={(instance): void => {
                setPlayerRef({ lottie: instance })
              }}
              loop
              src={animation}
              className='rf-w-full'
            />
          )}
        </div>
        {interimsVisible ? (
          <Icons.MicrophoneIconOn className='rf-h-3 rf-w-3 large:rf-h-4 large:rf-w-4' />
        ) : (
          <Icons.MicrophoneIcon
            className='rf-h-5 rf-w-5'
            color={themeColors.secondary}
          />
        )}
      </button>

      <Modal isOpened={!!message} onClose={closeModal}>
        {message}
      </Modal>
    </React.Fragment>
  )
}

export default MicrophoneButton
