import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import { useRetorik } from './RetorikContext'

import opentelemetry, {
  Attributes,
  SpanStatusCode,
  Tracer
} from '@opentelemetry/api'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import {
  BatchSpanProcessor,
  WebTracerProvider
} from '@opentelemetry/sdk-trace-web'
import { Resource } from '@opentelemetry/resources'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { telemetryAddress } from '../../models/constants'

export type TelemetryProviderProps = {
  enabled: boolean
  children?: ReactNode
}

type TelemetryContextType = {
  enabled: boolean
  tracer: Tracer | undefined
  sendErrorTrace: (x: Error) => void
}

const TelemetryContextDefaultValues: TelemetryContextType = {
  enabled: true,
  tracer: undefined,
  sendErrorTrace: () => {}
}

export const TelemetryContext = createContext<TelemetryContextType>(
  TelemetryContextDefaultValues
)

export function useTelemetry(): TelemetryContextType {
  return useContext(TelemetryContext)
}

export function TelemetryProvider({
  enabled,
  children
}: TelemetryProviderProps): JSX.Element {
  const { addressData } = useRetorik()
  const tracer = useMemo<Tracer | undefined>(() => {
    if (enabled) {
      const exporter = new OTLPTraceExporter({
        url: telemetryAddress,
        headers: {},
        concurrencyLimit: 1
      })

      const provider = new WebTracerProvider({
        resource: new Resource({
          [SemanticResourceAttributes.SERVICE_NAME]: 'retorik-framework'
        })
      })
      const processor = new BatchSpanProcessor(exporter)

      provider.addSpanProcessor(processor)
      provider.register()

      return opentelemetry.trace.getTracer('retorik-framework')
    }

    return undefined
  }, [enabled])

  const getCommonAttributes = (): Attributes => {
    let tenant = ''
    if (addressData.tenant) {
      tenant = addressData.prefix
        ? `${addressData.tenant} (${addressData.prefix})`
        : addressData.tenant
    }

    return {
      tenant: tenant,
      currentURL: window.location.toString()
    }
  }

  const sendErrorTrace = (error: Error): void => {
    if (tracer) {
      const span = tracer.startSpan('Error')

      span.recordException(error)
      span.setStatus({
        code: SpanStatusCode.ERROR,
        message: `${error.message}`
      })

      span.setAttributes(getCommonAttributes())

      span.end()
    }
  }

  const value = useMemo(
    () => ({
      enabled,
      tracer,
      sendErrorTrace
    }),
    [enabled, tracer]
  )

  return (
    <React.Fragment>
      <TelemetryContext.Provider value={value}>
        {children}
      </TelemetryContext.Provider>
    </React.Fragment>
  )
}
