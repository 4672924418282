import React, { useEffect, useState } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import HistoryList from '../History/HistoryList'
import LanguageChoice from '../Language/LanguageChoice'
import AttachmentDetails from '../Attachments/AttachmentDetail'
import FullScreenImage from '../Attachments/Utils/FullScreenImage'
import Weather from '../Weather/Weather'
import { CurrentSubView, RetorikEvent } from '../../models/enums'
import CustomView from './CustomView'
import {
  setCurrentCustomView,
  setCurrentSubView,
  setRetorikEvent,
  useUtilsStore
} from '../Contexts/utilsStore'
import { useLocaleStore } from '../Contexts/localeStore'
import useShowMap from '../../hooks/useShowMap'
import { CustomMenu } from '../../models/types'
import Emergency from '../Emergency/Emergency'
import useShowEmergency from '../../hooks/useShowEmergency'

const SubViews = ({ externalComponents }): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const currentCustomView = useUtilsStore((state) => state.currentCustomView)
  const { configuration } = useRetorik()
  const showMap = useShowMap()
  const showEmergency = useShowEmergency()
  const [displayCustomComponent, setDisplayCustomComponent] =
    useState<boolean>(true)
  const [customView, setCustomView] = useState<
    { view: string; title: string; icon?: JSX.Element } | undefined
  >(undefined)

  useEffect(() => {
    if (currentCustomView) {
      let label = ''
      if (
        currentCustomView.label &&
        typeof currentCustomView.label === 'string'
      ) {
        label = currentCustomView.label
      } else if (
        currentCustomView.label &&
        typeof currentCustomView.label === 'object'
      ) {
        label = currentCustomView.label[locale]
      }
      if (label && currentCustomView.customDisplay && currentCustomView.icon) {
        setCustomView({
          view: currentCustomView.customDisplay,
          title: label,
          icon: currentCustomView.icon
        })
      }
      setCurrentSubView(CurrentSubView.custom)
    }
  }, [currentCustomView])

  useEffect(() => {
    if (showMap && currentSubView !== CurrentSubView.custom) {
      if (externalComponents && externalComponents.length > 0) {
        const component = (
          externalComponents as Array<{ name: string; component: JSX.Element }>
        )?.find((e) => e.name === 'mapDisplay')
        if (component && component.component) {
          if (configuration.baseMenu || configuration.customMenu) {
            let allMenus: Array<CustomMenu> = []
            configuration.baseMenu &&
              // Filter on 'icon' key to get only CustomMenu items
              (allMenus = [...configuration.baseMenu].filter((m) =>
                Object.keys(m).includes('icon')
              ) as CustomMenu[])
            if (
              configuration.customMenu &&
              configuration.customMenu.length > 0
            ) {
              if (Array.isArray(configuration.customMenu[0])) {
                const tempCustomMenu = configuration.customMenu as [
                  CustomMenu[]
                ]
                tempCustomMenu.forEach((menu: CustomMenu[]) => {
                  allMenus = [...allMenus, ...menu]
                })
              } else {
                allMenus = [
                  ...allMenus,
                  ...(configuration.customMenu as CustomMenu[])
                ]
              }
            }

            const customMenuMap = allMenus.find(
              (c) => c.customDisplay && c.customDisplay === 'mapDisplay'
            )
            if (customMenuMap) {
              console.log(component.component)
              if (
                customView &&
                customView.view === customMenuMap.customDisplay
              ) {
              } else {
                setCurrentCustomView(customMenuMap)
              }
              setRetorikEvent(RetorikEvent.DetailViewOpen)
              setCurrentSubView(CurrentSubView.custom)
              setDisplayCustomComponent(true)
            }
          }
        }
      }
    }
  }, [showMap])

  useEffect(() => {
    if (showEmergency) {
      setCurrentSubView(CurrentSubView.emergency)
    }
  }, [showEmergency])

  useEffect(() => {
    if (currentSubView !== CurrentSubView.none) {
      setRetorikEvent(RetorikEvent.DetailViewOpen)
    } else {
      setRetorikEvent(RetorikEvent.DetailViewClose)
    }

    if (currentSubView !== CurrentSubView.custom) {
      setDisplayCustomComponent(false)
    } else {
      setDisplayCustomComponent(true)
    }
  }, [currentSubView])

  return (
    <React.Fragment>
      {currentSubView === CurrentSubView.history ? (
        <HistoryList />
      ) : currentSubView === CurrentSubView.languages ? (
        <LanguageChoice />
      ) : currentSubView === CurrentSubView.weather ? (
        <Weather />
      ) : currentSubView === CurrentSubView.emergency ? (
        <Emergency />
      ) : (
        <React.Fragment />
      )}

      <CustomView
        display={displayCustomComponent}
        externalComponents={externalComponents}
        customView={customView?.view}
        title={customView?.title}
        icon={customView?.icon}
        setDisplayCustomComponent={setDisplayCustomComponent}
      />

      <AttachmentDetails />
      <FullScreenImage />
    </React.Fragment>
  )
}

export default SubViews
