/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useRenderAttachment } from 'botframework-webchat-api/lib/hooks'
import { Mode } from '../../models/enums'
import Card from '../AnswerPanel/Card'
import Carousel from '../AnswerPanel/Carousel'
import { useUtilsStore } from '../Contexts/utilsStore'

interface AttachmentsProps {
  activity: any
  history?: boolean
}

const classNameText = 'rf-max-h-full'
const classNameVocal =
  'rf-w-11/12 large:rf-w-3/5 large-vertical:rf-w-4/9 rf-min-w-11/12 large:rf-min-w-3/5 large-vertical:rf-min-w-4/9 rf-max-h-full'

const Attachments = ({ activity, history }: AttachmentsProps): JSX.Element => {
  const renderAttachment = useRenderAttachment()
  const { mode } = useRetorik()
  const waitingForData = useUtilsStore((state) => state.waitingForData)
  const [height, setHeight] = useState<number>(0)
  const attachments = useMemo<Array<any>>(() => {
    return activity?.attachments || []
  }, [activity])
  const className = useMemo<string>(() => {
    return mode === Mode.text ? classNameText : classNameVocal
  }, [mode])
  const timerRef = useRef<NodeJS.Timeout>()
  const carouselRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null)

  useEffect(() => {
    setHeight(0)
    if (activity?.attachments?.length && activity?.attachments?.length > 1) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        carouselRef.current && setHeight(carouselRef.current.clientHeight)
      }, 500)
    }

    return () => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [activity.id])

  return mode === Mode.vocal && waitingForData ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      {attachments?.length > 1 ? (
        // Displayed carousel with cards having all the same min-height
        <Carousel ref={carouselRef} history={history} draft={height === 0}>
          {height === 0
            ? attachments.map((attachment, key) => {
                return (
                  <Card key={key + 100} className='rf-w-full'>
                    {renderAttachment({
                      activity: activity,
                      attachment: attachment,
                      history: history
                    })}
                  </Card>
                )
              })
            : attachments.map((attachment, key) => {
                return (
                  <Card key={key} className='rf-w-full'>
                    {renderAttachment({
                      activity: activity,
                      attachment: attachment,
                      history: history,
                      height: height
                    })}
                  </Card>
                )
              })}
        </Carousel>
      ) : (
        <Card className={history ? 'rf-w-full' : className}>
          {renderAttachment({
            activity: activity,
            attachment: attachments[0],
            history: history
          })}
        </Card>
      )}
    </React.Fragment>
  )
}

export default Attachments
