import React from 'react'
import type { Media } from '../../models/attachmentTypes'
import { Carousel } from '../Utils'

interface NewsAttachmentProps {
  title: string
  subtitle: string
  text: string
  media?: Array<{
    url: string
    profile?: string
  }>
  height?: number
}

const NewsAttachment = ({
  title,
  subtitle,
  text,
  media,
  height
}: NewsAttachmentProps): JSX.Element => {
  const medias: Array<Media> = []
  media &&
    media.forEach((med) => {
      medias.push({
        url: med.url,
        alt: med.profile || 'media'
      })
    })

  return (
    <div
      className='rf-w-full rf-p-4 rf-flex rf-flex-col rf-gap-4 rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText'
      style={{
        height: height,
        touchAction: 'pan-y'
      }}
    >
      {/* Title */}
      <div className='rf-w-fit rf-px-2 rf-py-1 large:rf-py-2 rf-small-size-auto rf-uppercase rf-border rf-border-black rf-bg-black rf-text-primary'>
        {title}
      </div>

      {/* SubTitle */}
      <div className='rf-title-size-auto'>{subtitle}</div>

      {/* Medias + information text */}
      <div className='rf-w-full rf-flex rf-flex-row rf-items-start rf-gap-3'>
        {/* Medias */}
        {medias.length > 0 && (
          <div className='rf-w-[45%] rf-min-w-[45%] rf-max-w-[45%] rf-flex rf-justify-center rf-aspect-square large:rf-aspect-video'>
            <Carousel
              className='rf-max-h-full rf-max-w-full rf-h-fit rf-w-fit'
              medias={medias}
            />
          </div>
        )}
        {/* Information text */}
        <div className='rf-line-clamp-7'>{text}</div>
      </div>
    </div>
  )
}

export default NewsAttachment
