const selectVoice = (
  voices,
  locale,
  customVoice,
  agentData
): SpeechSynthesisVoice | null => {
  let voice: SpeechSynthesisVoice | null = null
  // Check if a custom voice is defined in the props
  if (customVoice) {
    if (customVoice.voice) {
      // Search voice with its full name (ex: AriaNeural, GuyNeural)
      const neuralVoice = new RegExp(customVoice.voice, 'iu')
      voice = voices.find(
        ({ name }) => neuralVoice.test(name) || name === customVoice.voice
      )
    } else if (customVoice.gender) {
      // Search voice depending on given gender and locale
      voice = voices.find(({ gender, lang }) => {
        return (
          gender.toLowerCase() === customVoice?.gender?.toLowerCase() &&
          lang === locale
        )
      })
    }
  }
  // Get voice depending on locale and gender
  if (!voice) {
    voice = voices.find(({ gender, lang }) => {
      return (
        gender.toLowerCase() ===
          (agentData?.gender?.toLowerCase() || 'female') && lang === locale
      )
    })
  }
  // No voice found, fall back to US default voices : female => Aria / male => Guy
  if (!voice) {
    const genderedVoice =
      agentData?.gender?.toLowerCase() === 'female' ? 'AriaNeural' : 'GuyNeural'
    const neuralVoice = new RegExp(genderedVoice, 'iu')
    voice = voices.find(({ name }) => neuralVoice.test(name))
  }

  return voice
}

export { selectVoice }
